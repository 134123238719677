import React from 'react';

import { updateLocation } from 'app/core/actions';
import { connect } from 'react-redux';
import { StoreState } from 'app/types';
import { PureComponent } from 'react';
import { hot } from 'react-hot-loader';

interface Props {
  routeParams?: any;
  updateLocation?: typeof updateLocation;
  children: (props: {
    isLoginViewShow: boolean;
    showSignInView: () => void;
    showRegisterView: () => void;
  }) => JSX.Element;
}

interface State {
  isLoginViewShow: boolean;
}

export class LoginHeaderCtrl extends PureComponent<Props, State> {
  result: any = {};
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoginViewShow: true,
    };
  }

  showSignInView = () => {
    this.setState({
      isLoginViewShow: true,
    });
  };

  showRegisterView = () => {
    this.setState({
      isLoginViewShow: false,
    });
  };

  render() {
    const { children } = this.props;
    const { isLoginViewShow } = this.state;
    const { showSignInView, showRegisterView } = this;

    return (
      <>
        {children({
          isLoginViewShow,
          showSignInView,
          showRegisterView,
        })}
      </>
    );
  }
}

export const mapStateToProps = (state: StoreState) => ({
  routeParams: state.location.routeParams,
});

const mapDispatchToProps = { updateLocation };

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(LoginHeaderCtrl));
