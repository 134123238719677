import React, { PureComponent } from 'react';
import appEvents from '../../app_events';
import TopSection from './TopSection';
import BottomSection from './BottomSection';
import TopSectionButton from './TopSectionButton';
import config from 'app/core/config';
import { CoreEvents } from 'app/types';
import { Branding } from 'app/core/components/Branding/Branding';

const homeUrl = config.appSubUrl || '/';

export class SideMenu extends PureComponent {
  isMenuOpenedOnClick = false;
  isMenuOpened = false;
  toggleSideMenuSmallBreakpoint = () => {
    appEvents.emit(CoreEvents.toggleSidemenuMobile);
  };
  toggleSideMenuLgHiddenOnClick = () => {
    this.isMenuOpenedOnClick = false;
    appEvents.emit(CoreEvents.toggleSidemenuHiddenDesktop);
    this.isMenuOpened = false;
    this.removeItemClass();
  };
  toggleSideMenuLgBreakpointOnClick = () => {
    this.isMenuOpenedOnClick = true;
    this.isMenuOpened = true;
    document.body.classList.remove('sidemenu-hover');
    appEvents.emit(CoreEvents.toggleSidemenuDesktop);
  };
  toggleSideMenuLgHidden = () => {
    if (!this.isMenuOpenedOnClick) {
      appEvents.emit(CoreEvents.toggleSidemenuHiddenDesktop);
      this.isMenuOpened = false;
      this.removeItemClass();
    }
  };
  toggleSideMenuLgBreakpoint = () => {
    this.isMenuOpened = true;
    appEvents.emit(CoreEvents.toggleSidemenuDesktop);
  };
  toggleSideMenuMouseEnter = () => {
    if (!this.isMenuOpenedOnClick) {
      document.body.classList.add('sidemenu-hover');
      appEvents.emit(CoreEvents.toggleSidemenuDesktop);
      this.isMenuOpened = true;
    }
  };
  toggleSideMenuMouseOut = () => {
    if (!this.isMenuOpenedOnClick) {
      if (this.isMenuOpened) {
        appEvents.emit(CoreEvents.toggleSidemenuHiddenDesktop);
        this.isMenuOpened = false;
        this.removeItemClass();
      }
    }
  };
  removeItemClass = () => {
    setTimeout(() => {
      const alreadyOpenedSubmenu = document.querySelector('.show-item');
      if (alreadyOpenedSubmenu !== null && !this.isMenuOpened) {
        alreadyOpenedSubmenu.classList.remove('show-item');
      }
    }, 100);
  };
  toggleItemDropdown = (e: any) => {
    const clickedSubmenu = document.getElementById(e.currentTarget.id);
    const alreadyOpenedSubmenu = document.querySelector('.show-item');
    const onChildrenClick = e.target.parentElement.classList.contains('sidemenu-dropdown-child-link');
    if (onChildrenClick) {
      const activeLink = document.body.querySelector('.active-link');
      if (activeLink !== null) {
        activeLink.classList.remove('active-link');
      }
      e.target.classList.add('active-link');
    }
    if (!clickedSubmenu.classList.contains('show-item')) {
      if (alreadyOpenedSubmenu !== null) {
        alreadyOpenedSubmenu.classList.remove('show-item');
      }
    }
    if (clickedSubmenu && !onChildrenClick) {
      clickedSubmenu.classList.toggle('show-item');
    }
  };

  render() {
    return [
      <a href={homeUrl} className="sidemenu__logo" key="logo">
        <Branding.MenuLogo />
      </a>,
      <div className="sidemenu__logo_small_breakpoint" onClick={this.toggleSideMenuSmallBreakpoint} key="hamburger">
        <i className="fa fa-bars" />
        <span className="sidemenu__close">
          <i className="fa fa-times" />
          &nbsp;Close
        </span>
      </div>,
      <TopSectionButton
        key="toggleButton"
        onClickExpand={this.toggleSideMenuLgBreakpointOnClick}
        onClickCollapse={this.toggleSideMenuLgHiddenOnClick}
      />,
      <TopSection
        key="topsection"
        onMouseEnter={this.toggleSideMenuMouseEnter}
        onMouseLeave={this.toggleSideMenuMouseOut}
        openDropdown={this.toggleItemDropdown}
      />,
      <BottomSection
        key="bottomsection"
        onMouseEnter={this.toggleSideMenuMouseEnter}
        onMouseLeave={this.toggleSideMenuMouseOut}
        openDropdown={this.toggleItemDropdown}
      />,
    ];
  }
}
