import React, { PureComponent } from 'react';
import SearchResult from './SearchResult';
import clsx from 'clsx';
import { getBackendSrv } from '@grafana/runtime';

interface State {
  open: boolean;
  loading: boolean;
  data: any;
  searchValue: string;
}
export class SearchDropdown extends PureComponent<{}, State> {
  buttonRef = React.createRef();
  timeoutId: any;
  searchData: any;
  constructor(props: any) {
    super(props);
    this.getDashboards();
    this.state = { open: false, loading: false, data: null, searchValue: '' };
  }
  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  handleSearchChange = (event: any) => {
    this.setState({ data: null, searchValue: event.target.value });
    if (event.target.value.length > 1) {
      this.clearTimeout();
      this.setState({ loading: true });
      // simulate getting search result
      this.timeoutId = setTimeout(() => {
        this.setState({ data: this.searchData, loading: false });
      }, 500);
    }
  };
  getDashboards = async () => {
    const data = await getBackendSrv().get('/api/search?query=');
    this.searchData = data;
  };

  clear = () => {
    this.setState({ data: null, searchValue: '' });
  };

  componentWillUnmount() {
    this.clearTimeout();
  }

  render() {
    const { data, loading, searchValue } = this.state;

    return (
      <div id="searchDropdown">
        <div className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-lg">
          <div
            id="kt_quick_search_dropdown"
            className={clsx('kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact', {
              'kt-quick-search--has-result': data && data.length,
            })}
          >
            <form className="kt-quick-search__form">
              <div
                className={clsx('input-group', {
                  'kt-spinner kt-spinner--input kt-spinner--sm kt-spinner--brand kt-spinner--right': false,
                })}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="gicon gicon-nav-search" />
                  </span>
                </div>

                <input
                  type="text"
                  autoFocus={true}
                  placeholder="Search..."
                  value={searchValue}
                  onChange={this.handleSearchChange}
                  className="form-control kt-quick-search__input"
                />

                <div className="input-group-append">
                  <span className="input-group-text">
                    <i
                      style={{ display: 'flex' }}
                      onClick={this.clear}
                      hidden={!data || (data && !data.length)}
                      className="gicon gicon-close"
                    />
                    <i className={clsx({ 'fa fa-spinner fa-spin': loading })} />
                  </span>
                </div>
              </div>
            </form>
            <div
              className="kt-quick-search__wrapper kt-scroll"
              data-height="325"
              data-mobile-height="200"
              data-scroll="true"
              // options={perfectScrollbarOptions}
              style={{ maxHeight: '40vh' }}
            >
              <SearchResult data={data} searchValue={this.state.searchValue} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
