import React, { PureComponent, ReactElement } from 'react';
import { SelectableValue } from '@grafana/data';

interface Props<T> {
  className: string | undefined;
  options: Array<SelectableValue<T>>;
  value?: SelectableValue<T>;
  label?: ReactElement | string;
  iconClass?: string;
  onChange: (item: SelectableValue<T>) => void;
}
interface State {
  isMenuOpen: boolean;
}
export class RefreshPickerButtonSelect<T> extends PureComponent<Props<T>, State> {
  state: State = {
    isMenuOpen: false,
  };

  onChange = (item: SelectableValue<T>) => {
    const { onChange } = this.props;
    onChange(item);
    this.setState({ isMenuOpen: false });
  };

  onToggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  render() {
    const { isMenuOpen } = this.state;
    const { className, options, label, iconClass } = this.props;

    return (
      <>
        <div className={`btn navbar-button navbar-button--tight ${className}`} onClick={this.onToggleMenu}>
          <div className="select-button">
            {iconClass && <i className={`select-button-icon ${iconClass}`} />}
            <span className="select-button-value">{label ? label : ''}</span>
            {!isMenuOpen && <i className="fa fa-chevron-down" />}
            {isMenuOpen && <i className="fa fa-chevron-up" />}
          </div>
        </div>
        {isMenuOpen && (
          <div className="gf-form-select-box__menu">
            <div className="refresh-dropdown-bottomSection">
              <div className="refresh-interval-section">
                {options.map((option: any) => {
                  return (
                    <div className="refresh-interval-block" key={option.value}>
                      <button className={label === option.label ? 'active' : ''} onClick={() => this.onChange(option)}>
                        {option.label}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
