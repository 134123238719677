import React, { FC } from 'react';
import _ from 'lodash';
import TopSectionItem from './TopSectionItem';
import config from '../../config';

export interface Props {
  onMouseEnter: any;
  onMouseLeave: any;
  openDropdown: any;
}

const TopSection: FC<Props> = props => {
  const { onMouseEnter, onMouseLeave, openDropdown } = props;
  const navTree = _.cloneDeep(config.bootData.navTree);
  const mainLinks = _.filter(navTree, item => !item.hideFromMenu);

  return (
    <div className="sidemenu__top sidemenuScroll">
      {mainLinks.map((link, index) => {
        return (
          <TopSectionItem
            link={link}
            key={`${link.id}-${index}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            openDropdown={openDropdown}
          />
        );
      })}
    </div>
  );
};

export default TopSection;
