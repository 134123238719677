import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import formatISO from 'date-fns/formatISO';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { TimeRange, TimeZone } from '@grafana/data';
import { mapStringsToTimeRange } from '../TimePickerContent/mapper';
import set from 'date-fns/set';

interface Props {
  value: TimeRange;
  onApply: (range: TimeRange) => void;
  timeZone?: TimeZone;
  roundup?: boolean;
}
interface State {
  year: any;
  month: any;
}
export default class MonthRangeComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      year: this.props.value.from,
      month: this.props.value.from,
    };
    this.onApply = this.onApply.bind(this);
  }

  handleYearChange = (year: any | null) => {
    this.setState({
      year: year,
      month: year,
    });
  };

  handleMonthChange = (month: any | null) => {
    this.setState({
      month: month,
    });
  };

  onApply = () => {
    const startDay = set(startOfMonth(new Date(this.state.month)), { hours: 0, minutes: 0, seconds: 0 });
    const lastDay = set(lastDayOfMonth(new Date(this.state.month)), { hours: 23, minutes: 59, seconds: 59 });
    this.props.onApply(
      mapStringsToTimeRange(
        formatISO(startDay),
        formatISO(lastDay),
        this.props.roundup,
        this.props.timeZone,
        this.props.value.range_type
      )
    );
  };

  render() {
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="yyyy"
              openTo="year"
              views={['year']}
              margin="normal"
              id="year-picker-inline"
              label="Year/month"
              value={this.state.year}
              onChange={this.handleYearChange}
              KeyboardButtonProps={{
                'aria-label': 'change year',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              openTo="month"
              format="MMM"
              size="small"
              views={['month']}
              inputVariant="outlined"
              margin="normal"
              id="month-picker-inline"
              label="Month"
              value={this.state.month}
              onChange={this.handleMonthChange}
              KeyboardButtonProps={{
                'aria-label': 'change month',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid>
          <button className="timepicker--button-apply" onClick={this.onApply}>
            Apply
          </button>
        </Grid>
      </>
    );
  }
}
