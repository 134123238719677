import React from 'react';
import { appEvents } from 'app/core/core';

export class IconsModal extends React.PureComponent {
  static tabIndex = 0;

  getIcons() {
    const icons: any[] = [];
    const sheets = document.styleSheets;
    for (let i = 0; i < sheets.length; i++) {
      const sheet: any = sheets[i];
      try {
        var rules = sheet.cssRules;
        for (const rule of rules) {
          if (rule.selectorText && rule.selectorText.indexOf('--') === -1 && rule.selectorText.indexOf(':') === -1) {
            if (rule.selectorText.startsWith('.gicon-')) {
              let img: string = rule.cssText;
              img = img.substring(img.indexOf('img/'));
              img = img.substring(0, img.indexOf('"'));
              img = '/public/' + img;
              const cls: string = rule.selectorText.substring(1);
              icons.push([cls, img]);
            }
            if (rule.selectorText.startsWith('.sidemenu .gicon-')) {
              let img: string = rule.cssText;
              img = img.substring(img.indexOf('img/'));
              img = img.substring(0, img.indexOf('"'));
              img = '/public/' + img;
              const cls: string = rule.selectorText.substring(11);
              icons.push([cls, img]);
            }
          }
        }
      } catch (e) {}
    }
    return icons;
  }

  dismiss() {
    appEvents.emit('hide-modal');
  }

  render() {
    return (
      <div className="modal-body">
        <div className="modal-header">
          <h2 className="modal-header-title">
            <i className="fa fa-keyboard-o" />
            <span className="p-l-1">Icons</span>
          </h2>
          <a className="modal-header-close" onClick={this.dismiss}>
            <i className="fa fa-remove" />
          </a>
        </div>

        <div className="modal-content help-modal icons-modal">
          <p className="small" style={{ position: 'absolute', top: '13px', right: '44px' }}>
            <span className="muted">
              More icons can be found at
              <a href="https://fontawesome.com/v4.7.0/icons/">https://fontawesome.com/v4.7.0/icons/</a>
            </span>
          </p>
          {this.getIcons().map(([cls, img]) => (
            <div className="shortcut-category">
              <table className="shortcut-table">
                <tbody>
                  <tr>
                    <td className="shortcut-table-keys">
                      <img className="icon-table-key" src={img} />
                    </td>
                    <td className="shortcut-table-description">gicon {cls}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}
