// Libraries
import React, { PureComponent } from 'react';

import {
  Switch,
  FormField,
  FormLabel,
  ColorPicker,
  PanelOptionsGrid,
  FieldDisplayEditor,
  FieldPropertiesEditor,
  PanelOptionsGroup,
} from '@grafana/ui';

import { FieldConfig, PanelEditorProps, FieldDisplayOptions } from '@grafana/data';

import { CardsPanelOptions } from './types';

export class CardsPanelEditor extends PureComponent<PanelEditorProps<CardsPanelOptions>> {
  onDisplayOptionsChanged = (fieldOptions: FieldDisplayOptions) =>
    this.props.onOptionsChange({
      ...this.props.options,
      fieldOptions,
    });

  onDefaultsChange = (field: FieldConfig) => {
    this.onDisplayOptionsChanged({
      ...this.props.options.fieldOptions,
      defaults: field,
    });
  };

  onToggleChange = () =>
    this.props.onOptionsChange({
      ...this.props.options,
      showChange: !this.props.options.showChange,
    });

  onFilterChanged = (event: any) =>
    this.props.onOptionsChange({
      ...this.props.options,
      showChangeFilter: event.target.value,
    });

  onToggleSubmit = () =>
    this.props.onOptionsChange({
      ...this.props.options,
      disableSubmit: !this.props.options.disableSubmit,
    });

  onColorPickerChanged = (color: string) =>
    this.props.onOptionsChange({
      ...this.props.options,
      color: color,
    });

  onColorInputChanged = (event: any) =>
    this.props.onOptionsChange({
      ...this.props.options,
      color: event.target.value,
    });

  render() {
    const { options } = this.props;
    const { fieldOptions, showChange, showChangeFilter, disableSubmit, color } = options;
    const { defaults } = fieldOptions;

    return (
      <>
        <PanelOptionsGrid>
          <PanelOptionsGroup title="Display">
            <FieldDisplayEditor onChange={this.onDisplayOptionsChanged} value={fieldOptions} labelWidth={8} />
            <div className="form-field">
              <FormLabel width={8}>Color</FormLabel>
              <div className="cards-panel-colorpicker">
                <ColorPicker color={color} onChange={this.onColorPickerChanged} enableNamedColors={true} />
                <input className="gf-form-input width-12" value={color} onChange={this.onColorInputChanged} />
              </div>
            </div>
            <Switch label="Show Change" labelClass="width-8" checked={showChange} onChange={this.onToggleChange} />
            {showChange && (
              <>
                <FormField label="Filter" labelWidth={8} onChange={this.onFilterChanged} value={showChangeFilter} />
                <Switch
                  label="Disable Submit"
                  labelClass="width-8"
                  checked={disableSubmit}
                  onChange={this.onToggleSubmit}
                />
              </>
            )}
          </PanelOptionsGroup>

          <PanelOptionsGroup title="Field">
            <FieldPropertiesEditor onChange={this.onDefaultsChange} value={defaults} showTitle={true} />
          </PanelOptionsGroup>
        </PanelOptionsGrid>
      </>
    );
  }
}
