// Store
import store from 'app/core/store';

// Libaries
import { dateTime } from '@grafana/data';

// Models
import { DashboardModel } from 'app/features/dashboard/state/DashboardModel';
import { PanelModel, panelRemoved, panelAdded } from 'app/features/dashboard/state/PanelModel';
import { TimeRange, RawTimeRange, TimeZone, DateTime, AppEvents } from '@grafana/data';

// Utils
import { isString as _isString } from 'lodash';
import { rangeUtil } from '@grafana/data';
import { dateMath, isDateTime } from '@grafana/data';
import appEvents from 'app/core/app_events';
import config from 'app/core/config';

// Constants
import { LS_PANEL_COPY_KEY, PANEL_BORDER } from 'app/core/constants';
import { CoreEvents } from 'app/types';

import { ShareModal } from 'app/features/dashboard/components/ShareModal';

export const removePanel = (dashboard: DashboardModel, panel: PanelModel, ask: boolean) => {
  // confirm deletion
  if (ask !== false) {
    const text2 = panel.alert ? 'Panel includes an alert rule, removing panel will also remove alert rule' : null;
    const confirmText = panel.alert ? 'YES' : null;

    appEvents.emit(CoreEvents.showConfirmModal, {
      title: 'Remove Panel',
      text: 'Are you sure you want to remove this panel?',
      text2: text2,
      icon: 'fa-trash',
      confirmText: confirmText,
      yesText: 'Remove',
      onConfirm: () => removePanel(dashboard, panel, false),
    });
    return;
  }
  dashboard.removePanel(panel);
};

export const duplicatePanel = (dashboard: DashboardModel, panel: PanelModel) => {
  dashboard.duplicatePanel(panel);
};

export const copyPanel = (panel: PanelModel) => {
  store.set(LS_PANEL_COPY_KEY, JSON.stringify(panel.getSaveModel()));
  appEvents.emit(AppEvents.alertSuccess, ['Panel copied. Open Add Panel to paste']);
};

const replacePanel = (dashboard: DashboardModel, newPanel: PanelModel, oldPanel: PanelModel) => {
  const index = dashboard.panels.findIndex(panel => {
    return panel.id === oldPanel.id;
  });

  const deletedPanel = dashboard.panels.splice(index, 1)[0];
  dashboard.events.emit(panelRemoved, deletedPanel);

  newPanel = new PanelModel(newPanel);
  newPanel.id = oldPanel.id;

  dashboard.panels.splice(index, 0, newPanel);
  dashboard.sortPanelsByGridPos();
  dashboard.events.emit(panelAdded, newPanel);
};

export const editPanelJson = (dashboard: DashboardModel, panel: PanelModel) => {
  const model = {
    object: panel.getSaveModel(),
    updateHandler: (newPanel: PanelModel, oldPanel: PanelModel) => {
      replacePanel(dashboard, newPanel, oldPanel);
    },
    canUpdate: dashboard.meta.canEdit,
    enableCopy: true,
  };

  appEvents.emit(CoreEvents.showModal, {
    src: 'public/app/partials/edit_json.html',
    model: model,
  });
};

export const sharePanel = (dashboard: DashboardModel, panel: PanelModel) => {
  appEvents.emit(CoreEvents.showModalReact, {
    component: ShareModal,
    props: {
      dashboard: dashboard,
      panel: panel,
    },
  });
};

export const refreshPanel = (panel: PanelModel) => {
  panel.refresh();
};

export const toggleLegend = (panel: PanelModel) => {
  console.log('Toggle legend is not implemented yet');
  // We need to set panel.legend defaults first
  // panel.legend.show = !panel.legend.show;
  refreshPanel(panel);
};

export interface TimeOverrideResult {
  timeRange: TimeRange;
  timeInfo: string;
}

export function applyPanelTimeOverrides(panel: PanelModel, timeRange: TimeRange): TimeOverrideResult {
  const newTimeData = {
    timeInfo: '',
    timeRange: timeRange,
  };

  if (panel.timeOverride) {
    const toTimeInfo = (rawTime: RawTimeRange, timezone: TimeZone) => {
      const adjustedTime = (value: DateTime) => {
        if (timezone === 'utc') {
          return value.utc() || null;
        }
        return value.local() || null;
      };

      const time = toTimeRange(rawTime, timezone);
      const adjustedTimeRange = {
        to: dateMath.isMathString(time.raw.to) ? time.raw.to : adjustedTime(time.to),
        from: dateMath.isMathString(time.raw.from) ? time.raw.from : adjustedTime(time.from),
        range_type: time.raw.range_type,
      };
      return rangeUtil.describeTimeRange(adjustedTimeRange);
    };

    const toTimeRange = (time: RawTimeRange, timezone: TimeZone): TimeRange => {
      const raw = {
        from: isDateTime(time.from) ? dateTime(time.from) : time.from,
        to: isDateTime(time.to) ? dateTime(time.to) : time.to,
        range_type: time.range_type,
      };

      return {
        from: dateMath.parse(raw.from, false, timezone),
        to: dateMath.parse(raw.to, true, timezone),
        range_type: time.range_type,
        raw: raw,
      };
    };

    newTimeData.timeInfo = toTimeInfo(panel.timeRange, panel.timezone);
    newTimeData.timeRange = toTimeRange(panel.timeRange, panel.timezone);
  }

  if (panel.hideTimeOverride) {
    newTimeData.timeInfo = '';
  }

  return newTimeData;
}

export function getResolution(panel: PanelModel): number {
  const htmlEl = document.getElementsByTagName('html')[0];
  const width = htmlEl.getBoundingClientRect().width; // https://stackoverflow.com/a/21454625

  return panel.maxDataPoints ? panel.maxDataPoints : Math.ceil(width * (panel.gridPos.w / 24));
}

export function calculateInnerPanelHeight(panel: PanelModel, containerHeight: number): number {
  const chromePadding = panel.plugin && panel.plugin.noPadding ? 0 : config.theme.panelPadding * 2;
  const headerHeight = panel.hasTitle() ? config.theme.panelHeaderHeight : 0;
  return containerHeight - headerHeight - chromePadding - PANEL_BORDER;
}
