// Libraries
import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

// Components
import { LoginServiceButtons } from './LoginServiceButtons';
import LoginCtrl from './LoginCtrl';
import { LoginForm } from './LoginForm';
import { ChangePassword } from './ChangePassword';
import { LoginHeader } from './LoginHeader';
import { ForgotPassword } from './ForgotPassword';
import { RegisterForm } from './RegisterForm';
import { RegisterCtrl } from './RegisterCtrl';
import { LoginHeaderCtrl } from './LoginHeaderCtrl';

export const LoginPage: FC = () => {
  return (
    <LoginHeaderCtrl>
      {({ isLoginViewShow, showSignInView, showRegisterView }) => (
        <div className="login container">
          <LoginHeader showLogin={showSignInView} showRegister={showRegisterView} />
          <div className="login-content">
            <div className="login-branding"></div>
            {isLoginViewShow ? (
              <LoginCtrl>
                {({
                  loginHint,
                  passwordHint,
                  isOauthEnabled,
                  ldapEnabled,
                  authProxyEnabled,
                  disableLoginForm,
                  disableUserSignUp,
                  login,
                  isLoggingIn,
                  changePassword,
                  skipPasswordChange,
                  isChangingPassword,
                  isForgotPassword,
                  showForgotPasswordView,
                  onForgotPasswordBack,
                  resetPassword,
                }) => (
                  <div className="login-outer-box">
                    <div
                      className={`login-inner-box ${isChangingPassword || isForgotPassword ? 'hidden' : ''}`}
                      id="login-view"
                    >
                      <span className="login-header-lg">Modern Industrial AI done right.</span>
                      <br />
                      <div className="sm-head-section">
                        <span className="login-header-sm">Sign in to your Travit AI dashboard:</span>
                      </div>
                      {!disableLoginForm ? (
                        <LoginForm
                          displayForgotPassword={!(ldapEnabled || authProxyEnabled)}
                          onSubmit={login}
                          loginHint={loginHint}
                          passwordHint={passwordHint}
                          isLoggingIn={isLoggingIn}
                          onForgotPasswordClick={showForgotPasswordView}
                        />
                      ) : null}
                      <LoginServiceButtons />
                    </div>
                    <CSSTransition
                      appear={true}
                      mountOnEnter={true}
                      in={isChangingPassword}
                      timeout={250}
                      classNames="login-inner-box"
                    >
                      <ChangePassword
                        onSubmit={changePassword}
                        onSkip={skipPasswordChange}
                        focus={isChangingPassword}
                      />
                    </CSSTransition>
                    {isForgotPassword ? (
                      <ForgotPassword onResetPassword={resetPassword} onBack={onForgotPasswordBack} />
                    ) : null}
                  </div>
                )}
              </LoginCtrl>
            ) : (
              <RegisterCtrl>
                {({
                  signup,
                  getVerificationCode,
                  haveVerificationCode,
                  haveVerificationClick,
                  dontHaveVerificationClick,
                }) => (
                  <div className="login-outer-box">
                    <div className={`login-inner-box`} id="login-view">
                      <span className="login-header-lg fnt-24">Register</span>
                      <br />
                      <div className="sm-head-section">
                        <span className="login-header-sm">
                          {!haveVerificationCode ? 'We need to verify your corporate email first' : ''}
                        </span>
                      </div>
                      <RegisterForm
                        onSubmit={signup}
                        onContinue={getVerificationCode}
                        haveVerificationCode={haveVerificationCode}
                        haveVerificationClick={haveVerificationClick}
                        dontHaveVerificationClick={dontHaveVerificationClick}
                      />
                    </div>
                  </div>
                )}
              </RegisterCtrl>
            )}
            <div className="clearfix" />
          </div>
        </div>
      )}
    </LoginHeaderCtrl>
  );
};
