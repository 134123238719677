import React, { PureComponent } from 'react';

export default class TopSectionButton extends PureComponent<any> {
  render() {
    return (
      <div className="sidemenu-item dropdown top-toggle-button">
        <a className="sidemenu-link expand-btn" onClick={this.props.onClickExpand}>
          <span className="icon-circle sidemenu-icon">
            <i className="gicon gicon-expand" />
          </span>
        </a>
        <a className="sidemenu-link collaspe-btn" onClick={this.props.onClickCollapse}>
          <span className="icon-circle sidemenu-icon">
            <i className="gicon gicon-collapse" />
          </span>
        </a>
      </div>
    );
  }
}
