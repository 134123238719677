import React, { Component } from 'react';
import { TextField, Grid } from '@material-ui/core';
import { TimeRange, TimeZone } from '@grafana/data';
import { mapStringsToTimeRange } from '../TimePickerContent/mapper';

interface Props {
  value: TimeRange;
  onApply: (range: TimeRange) => void;
  timeZone?: TimeZone;
  roundup?: boolean;
}
interface State {
  from: any;
  to: any;
}
export default class TimeRangeComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      from: props.value.raw.from,
      to: props.value.raw.to,
    };
  }

  onFromChange = (event: any) => {
    this.setState({
      from: event.target.value,
    });
  };

  onToChange = (event: any) => {
    this.setState({
      to: event.target.value,
    });
  };

  onApply = () => {
    let { from, to } = this.state;
    this.props.onApply(
      mapStringsToTimeRange(from, to, this.props.roundup, this.props.timeZone, this.props.value.range_type)
    );
  };

  render() {
    return (
      <>
        <TextField
          label="From"
          variant="outlined"
          className="max-width timepicker-mat-select MuiFormControl-marginNormal"
          value={this.state.from}
          onChange={this.onFromChange}
        />
        <TextField
          label="To"
          variant="outlined"
          className="max-width timepicker-mat-select"
          value={this.state.to}
          onChange={this.onToChange}
        />
        <Grid>
          <button className="timepicker--button-apply" onClick={this.onApply}>
            Apply
          </button>
        </Grid>
      </>
    );
  }
}
