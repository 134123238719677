// Libraries
import React, { PureComponent } from 'react';
import {
  ThresholdsEditor,
  PanelOptionsGrid,
  ValueMappingsEditor,
  FieldDisplayEditor,
  FieldPropertiesEditor,
  Switch,
  PanelOptionsGroup,
  DataLinksEditor,
  FormLabel,
  FormField,
  ColorPicker,
} from '@grafana/ui';
import {
  PanelEditorProps,
  FieldDisplayOptions,
  ThresholdsConfig,
  ValueMapping,
  FieldConfig,
  DataLink,
  toNumberString,
  toFloatOrUndefined,
} from '@grafana/data';

import { GaugeOptions } from './types';
import {
  getCalculationValueDataLinksVariableSuggestions,
  getDataLinksVariableSuggestions,
} from 'app/features/panel/panellinks/link_srv';

export class GaugePanelEditor extends PureComponent<PanelEditorProps<GaugeOptions>> {
  labelWidth = 6;

  onToggleThresholdLabels = () =>
    this.props.onOptionsChange({ ...this.props.options, showThresholdLabels: !this.props.options.showThresholdLabels });

  onToggleThresholdMarkers = () =>
    this.props.onOptionsChange({
      ...this.props.options,
      showThresholdMarkers: !this.props.options.showThresholdMarkers,
    });

  onThresholdsChanged = (thresholds: ThresholdsConfig) => {
    const current = this.props.options.fieldOptions.defaults;
    this.onDefaultsChange({
      ...current,
      thresholds,
    });
  };

  onValueMappingsChanged = (mappings: ValueMapping[]) => {
    const current = this.props.options.fieldOptions.defaults;
    this.onDefaultsChange({
      ...current,
      mappings,
    });
  };

  onDisplayOptionsChanged = (
    fieldOptions: FieldDisplayOptions,
    event?: React.SyntheticEvent<HTMLElement>,
    callback?: () => void
  ) =>
    this.props.onOptionsChange(
      {
        ...this.props.options,
        fieldOptions,
      },
      callback
    );

  onDefaultsChange = (field: FieldConfig, event?: React.SyntheticEvent<HTMLElement>, callback?: () => void) => {
    this.onDisplayOptionsChanged(
      {
        ...this.props.options.fieldOptions,
        defaults: field,
      },
      event,
      callback
    );
  };

  onDataLinksChanged = (links: DataLink[], callback?: () => void) => {
    this.onDefaultsChange(
      {
        ...this.props.options.fieldOptions.defaults,
        links,
      },
      undefined,
      callback
    );
  };

  onColorChanged = (color: any) => {
    if (typeof color === 'string') {
      this.props.onOptionsChange({
        ...this.props.options,
        backgroundColor: color,
      });
    } else {
      this.props.onOptionsChange({
        ...this.props.options,
        backgroundColor: color.target.value,
      });
    }
  };

  onWidthChanged = (event: any) =>
    this.props.onOptionsChange({
      ...this.props.options,
      gaugeWidth: toFloatOrUndefined(event.target.value),
    });

  render() {
    const { options } = this.props;
    const { fieldOptions, showThresholdLabels, showThresholdMarkers, backgroundColor, gaugeWidth } = options;
    const { defaults } = fieldOptions;

    const suggestions = fieldOptions.values
      ? getDataLinksVariableSuggestions(this.props.data.series)
      : getCalculationValueDataLinksVariableSuggestions(this.props.data.series);

    return (
      <>
        <PanelOptionsGrid>
          <PanelOptionsGroup title="Display">
            <FieldDisplayEditor
              onChange={this.onDisplayOptionsChanged}
              value={fieldOptions}
              labelWidth={this.labelWidth}
            />
            <Switch
              label="Labels"
              labelClass={`width-${this.labelWidth}`}
              checked={showThresholdLabels}
              onChange={this.onToggleThresholdLabels}
            />
            <Switch
              label="Markers"
              labelClass={`width-${this.labelWidth}`}
              checked={showThresholdMarkers}
              onChange={this.onToggleThresholdMarkers}
            />
            <div className="form-field">
              <FormLabel width={this.labelWidth}>Color</FormLabel>
              <div className="cards-panel-colorpicker">
                <ColorPicker color={backgroundColor || ''} onChange={this.onColorChanged} enableNamedColors={true} />
                <input className="gf-form-input width-12" value={backgroundColor} onChange={this.onColorChanged} />
              </div>
            </div>
            <FormField
              label="Width"
              labelWidth={this.labelWidth}
              placeholder="1"
              onChange={this.onWidthChanged}
              value={toNumberString(gaugeWidth)}
              type="number"
            />
          </PanelOptionsGroup>

          <PanelOptionsGroup title="Field">
            <FieldPropertiesEditor
              showMinMax={true}
              showTitle={true}
              onChange={this.onDefaultsChange}
              value={defaults}
            />
          </PanelOptionsGroup>

          <ThresholdsEditor onChange={this.onThresholdsChanged} thresholds={defaults.thresholds} />
        </PanelOptionsGrid>

        <ValueMappingsEditor onChange={this.onValueMappingsChanged} valueMappings={defaults.mappings} />

        <PanelOptionsGroup title="Data links">
          <DataLinksEditor
            value={defaults.links}
            onChange={this.onDataLinksChanged}
            suggestions={suggestions}
            maxLinks={10}
          />
        </PanelOptionsGroup>
      </>
    );
  }
}
