// Libraries
import React, { PureComponent } from 'react';

// Utils & Services
import { config } from 'app/core/config';

// Types
import { DiffPanelOptions } from './types';
import { VizRepeater, BigValue, BigValueJustifyMode } from '@grafana/ui';

import {
  PanelProps,
  getFieldDisplayValues,
  FieldDisplay,
  getDisplayValueAlignmentFactors,
  DisplayValueAlignmentFactors,
  VizOrientation,
} from '@grafana/data';

export class DiffPanel extends PureComponent<PanelProps<DiffPanelOptions>> {
  renderValue = (
    value: FieldDisplay,
    width: number,
    height: number,
    alignmentFactors: DisplayValueAlignmentFactors
  ): JSX.Element => {
    let scale = 1;
    let className = 'diffstat-panel-item';
    if (value.name === 'difference') {
      if (value.display.numeric > 0) {
        className += ' diffstat-panel-item--increasing';
      } else if (value.display.numeric < 0) {
        className += ' diffstat-panel-item--decreasing';
      } else {
        className += ' diffstat-panel-item--invalid';
      }
    } else {
      scale = 2 / 3;
    }

    return (
      <BigValue
        value={value.display}
        alignmentFactors={alignmentFactors}
        width={width}
        height={height}
        theme={config.theme}
        className={className}
        justifyMode={BigValueJustifyMode.Auto}
        scale={scale}
      />
    );
  };

  getValues = (): FieldDisplay[] => {
    const { data, options, replaceVariables } = this.props;

    const values = getFieldDisplayValues({
      ...options,
      replaceVariables,
      theme: config.theme,
      data: data.series,
      autoMinMax: true,
    });

    let t = parseFloat(values[0].display.text) - parseFloat(values[1].display.text);
    t = Math.round((100 * t) / parseFloat(values[1].display.text));

    let n = values[0].display.numeric - values[1].display.numeric;
    n = (100 * n) / values[1].display.numeric;

    let p = '';
    if (t > 0) {
      p = '▲';
    } else if (t < 0) {
      p = '▼';
    }

    values.unshift({
      name: 'difference',
      display: {
        text: Math.abs(t).toString(),
        prefix: p,
        suffix: ' %',
        numeric: n,
        title: options.title || 'Difference',
      },
      field: {},
    });

    return values;
  };

  render() {
    const { height, options, width, data, renderCounter } = this.props;

    if (data.series.length !== 2) {
      return (
        <div style={{ height: '100%', display: 'flex' }}>
          <span style={{ margin: 'auto' }}>This panel requires exactly two series!</span>
        </div>
      );
    }

    return (
      <VizRepeater
        getValues={this.getValues}
        getAlignmentFactors={getDisplayValueAlignmentFactors}
        renderValue={this.renderValue}
        width={width}
        height={height}
        source={data}
        renderCounter={renderCounter}
        orientation={getOrientation(width, height, options.orientation)}
      />
    );
  }
}

/**
 * Diff panel custom auto orientation
 */
function getOrientation(width: number, height: number, orientation: VizOrientation): VizOrientation {
  if (orientation !== VizOrientation.Auto) {
    return orientation;
  }

  if (width / height > 2) {
    return VizOrientation.Vertical;
  } else {
    return VizOrientation.Horizontal;
  }
}
