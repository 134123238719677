import React from 'react';

interface Props {
  data: any;
  searchValue: string;
}
export default class SearchResult extends React.Component<Props> {
  filteredData: any;

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { data, searchValue } = this.props;
    if (data) {
      this.filteredData = data.filter((dashbord: any) => {
        return dashbord.title.toLowerCase().indexOf(searchValue) !== -1;
      });
    }

    if (!data) {
      return null;
    }

    if (this.filteredData.length === 0) {
      return (
        <div className="kt-quick-search__result">
          <div className="kt-quick-search__message">No record found</div>
        </div>
      );
    }

    return (
      <div className="kt-quick-search__result">
        {this.filteredData.map((item: any, index: number) => (
          <React.Fragment key={index}>
            <a href={item.url} className="kt-quick-search__item">
              <div className="kt-quick-search__item-icon">
                <i className="gicon gicon-dashboard"></i>
              </div>
              <div className="kt-quick-search__item-wrapper">
                <div className="kt-quick-search__item-title">{item.title}</div>
                <div className="kt-quick-search__item-desc">{item.folderTitle}</div>
              </div>
            </a>
          </React.Fragment>
        ))}
      </div>
    );
  }
}
