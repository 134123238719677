import _ from 'lodash';
import { getBackendSrv } from '@grafana/runtime';

import config from 'app/core/config';
import { StoreState } from '../../../types';
import { VariableModel } from '../variable';
import { getState, dispatch } from '../../../store/store';
import { EMPTY_UUID } from './types';
import { variableAdapters } from '../adapters';
import { initDashboardTemplating, processVariables } from './actions';

export const getVariable = <T extends VariableModel = VariableModel>(
  uuid: string,
  state: StoreState = getState()
): T => {
  loadGlobalVariables();
  if (!state.templating.variables[uuid]) {
    throw new Error(`Couldn't find variable with uuid:${uuid}`);
  }

  return state.templating.variables[uuid] as T;
};

export const getVariableWithName = (name: string, state: StoreState = getState()) => {
  return Object.values(state.templating.variables).find(variable => variable.name === name);
};

export const getVariables = (state: StoreState = getState()): VariableModel[] => {
  return Object.values(state.templating.variables).filter(variable => variable.uuid! !== EMPTY_UUID);
};

export const getVariableClones = (state: StoreState = getState(), includeEmptyUuid = false): VariableModel[] => {
  const variables = Object.values(state.templating.variables)
    .filter(variable => (includeEmptyUuid ? true : variable.uuid !== EMPTY_UUID))
    .map(variable => _.cloneDeep(variable));
  return variables.sort((s1, s2) => s1.index! - s2.index!);
};

export const getGlobalVariables = () => {
  let variables = JSON.parse(config.variables || '[]');
  variables = variables.map((v: any) => {
    if (!v || !v.name) {
      v = undefined;
    }
    if (!v.name.startsWith('__cfg_')) {
      v.name = '__cfg_' + v.name;
    }
    return v;
  });
  _.compact(variables);

  return variables;
};

export const loadGlobalVariables = async (state: StoreState = getState()) => {
  const _variables = getVariableClones(state).filter(v => v.name.startsWith('__cfg_'));
  if (_variables.length === 0) {
    const variables = getGlobalVariables();
    if (variables.length) {
      await dispatch(initDashboardTemplating(variables));
      await dispatch(processVariables());
    }
  }
};

export const updateGlobalVariables = (state: StoreState = getState()) => {
  const _variables = _.orderBy(getVariableClones(state), 'index').filter(v => v.name.startsWith('__cfg_'));
  if (_variables.length) {
    const variables = [];
    for (const variable of _variables) {
      variables.push(variableAdapters.get(variable.type).getSaveModel(variable));
    }
    if (JSON.stringify(variables) !== config.variables) {
      config.variables = JSON.stringify(variables);
      getBackendSrv().put('/api/org/variables', { variables: config.variables });
    }
  }
};
