import React, { PureComponent, SyntheticEvent, ChangeEvent } from 'react';
import { FormModel } from './RegisterCtrl';

interface Props {
  haveVerificationCode: boolean;
  onContinue: (email: string) => void;
  onSubmit: (data: FormModel) => void;
  haveVerificationClick: () => void;
  dontHaveVerificationClick: () => void;
}
interface State {
  corporateEmail: string;
  orgName: string;
  verificationCode: number;
  newPassword: string;
  confirmPassword: string;
  accept: boolean;
  valid: boolean;
  validContinue: boolean;
}
export class RegisterForm extends PureComponent<Props, State> {
  private userInput: HTMLInputElement;
  constructor(props: any) {
    super(props);
    this.state = {
      corporateEmail: '',
      orgName: '',
      verificationCode: null,
      newPassword: '',
      confirmPassword: '',
      accept: false,
      valid: false,
      validContinue: false,
    };
  }
  focus() {
    this.userInput.focus();
  }
  myChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const nam = e.target.name;
    let val: any;
    if (nam === 'accept') {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }
    this.setState({ [nam]: val } as any, () => {
      this.validateRequiredField();
      if (nam === 'corporateEmail') {
        this.setState({
          validContinue: val && val.length > 0,
        });
      }
    });
  };
  validateRequiredField() {
    const isValid =
      this.state.corporateEmail &&
      this.state.orgName &&
      this.state.verificationCode &&
      this.state.newPassword &&
      this.state.confirmPassword &&
      this.state.accept &&
      this.state.newPassword === this.state.confirmPassword;
    this.setState({
      valid: isValid,
    });
  }
  onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const { corporateEmail, orgName, verificationCode, newPassword, confirmPassword } = this.state;
    if (this.state.valid) {
      this.props.onSubmit({ corporateEmail, orgName, verificationCode, newPassword, confirmPassword });
    }
  };
  onContinue = (e: SyntheticEvent) => {
    e.preventDefault();
    const { corporateEmail } = this.state;
    if (this.state.validContinue) {
      this.props.onContinue(corporateEmail);
    }
  };

  render() {
    return (
      <form name="RegisterForm" className="login-form-group gf-form-group">
        <div className="login-form">
          <input
            type="email"
            id="corporateEmail"
            name="corporateEmail"
            autoComplete="corporate-Email"
            className="gf-form-input login-form-input"
            required
            placeholder="your corporate email"
            onChange={this.myChangeHandler}
          />
        </div>
        {this.props.haveVerificationCode ? (
          <div className="wd-100">
            <div className="login-form">
              <input
                id="orgName"
                name="orgName"
                className="gf-form-input login-form-input"
                required
                placeholder="organisation name"
                onChange={this.myChangeHandler}
              />
            </div>
            <div className="login-form">
              <input
                id="verificationCode"
                type="number"
                name="verificationCode"
                className="gf-form-input login-form-input"
                required
                placeholder="verification code"
                onChange={this.myChangeHandler}
              />
            </div>
            <div className="login-form">
              <input
                id="newPassword"
                type="password"
                autoComplete="new-Password"
                name="newPassword"
                className="gf-form-input login-form-input"
                required
                placeholder="new password"
                onChange={this.myChangeHandler}
              />
            </div>
            <div className="login-form">
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                className="gf-form-input login-form-input"
                required
                placeholder="confirm password"
                onChange={this.myChangeHandler}
              />
            </div>
            <div className="login-form">
              <label className="login-container">
                I have read and accept the{' '}
                <a target="_blank" href="#">
                  Terms & Conditions
                </a>
                <input
                  type="checkbox"
                  id="accept"
                  name="accept"
                  checked={this.state.accept}
                  onChange={this.myChangeHandler}
                  required
                />
                <span className="login-checkmark" />
              </label>
            </div>
          </div>
        ) : null}
        <div className="login-button-group">
          {!this.props.haveVerificationCode ? (
            <button
              type="submit"
              aria-label="Login button"
              className={`btn btn-medium p-x-2 ${this.state.validContinue ? 'btn-primary' : 'btn-inverse'}`}
              disabled={!this.state.validContinue}
              onClick={this.onContinue}
            >
              Continue
            </button>
          ) : (
            <button
              type="submit"
              aria-label="Login button"
              className={`btn btn-medium p-x-2 ${this.state.valid ? 'btn-primary' : 'btn-inverse'}`}
              disabled={!this.state.valid}
              onClick={this.onSubmit}
            >
              Sign Up{' '}
            </button>
          )}

          <div className="small login-button-forgot-password">
            {!this.props.haveVerificationCode ? (
              <a onClick={this.props.haveVerificationClick}>Have verification code?</a>
            ) : (
              <a onClick={this.props.dontHaveVerificationClick}>Don't Have verification code?</a>
            )}
          </div>
        </div>
      </form>
    );
  }
}
