import _ from 'lodash';
import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { TimeRange, TimeZone } from '@grafana/data';
import { mapOptionToTimeRange } from '../TimePickerContent/mapper';

interface Props {
  value: any;
  otherOptions: any;
  quickOptions: any;
  onApply: (range: TimeRange) => void;
  timeZone?: TimeZone;
}
export default class PresetsRangeComponent extends Component<Props> {
  render() {
    const { quickOptions, otherOptions, onApply, timeZone, value } = this.props;
    return (
      <>
        <Container>
          <Row>
            {_.concat(quickOptions, otherOptions).map((option: any, i: number) => {
              option.range_type = value.range_type;
              return (
                <Col md={4} className="preset-col-mrg" key={i}>
                  <Button className="max-width" key={i} onClick={() => onApply(mapOptionToTimeRange(option, timeZone))}>
                    {option.display}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </Container>
      </>
    );
  }
}
