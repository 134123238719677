import React, { PureComponent } from 'react';
import { Select, MenuItem, Grid } from '@material-ui/core';
import { mapStringsToTimeRange } from '../TimePickerContent/mapper';
import { TimeRange, TimeZone } from '@grafana/data';
import format from 'date-fns/format';

interface Props {
  historyOptions: any;
  onApply: (range: TimeRange) => void;
  value: any;
  timeZone?: TimeZone;
}
interface State {
  history?: any;
}
export default class RecentRangeComponent extends PureComponent<Props, State> {
  constructor(props: any) {
    super(props);
    const from = format(new Date(this.props.value.from), 'yyyy-MM-dd hh:mm:ss');
    const to = format(new Date(this.props.value.to), 'yyyy-MM-dd hh:mm:ss');
    const value = this.props.historyOptions.filter((option: any) => {
      if (from === option.from && to === option.to) {
        return option;
      }
    });
    this.state = {
      history: value[0] || this.props.historyOptions[0],
    };
    this.changeHistory = this.changeHistory.bind(this);
    this.onApply = this.onApply.bind(this);
  }

  changeHistory = (event: any) => {
    if (event) {
      this.setState({
        history: event.target.value,
      });
    }
  };

  renderOptions() {
    return this.props.historyOptions.map((type: any, i: number) => {
      return (
        <MenuItem value={type} key={i}>
          {type.display}
        </MenuItem>
      );
    });
  }

  onApply = () => {
    const { history } = this.state;
    this.props.onApply(
      mapStringsToTimeRange(history.from, history.to, undefined, this.props.timeZone, this.props.value.range_type)
    );
  };

  render() {
    return (
      <>
        <div className="recent-timpicker-container">
          <Select
            className="max-width timepicker-material-fields timepicker-mat-select"
            label="Select Recent Option"
            value={this.state.history}
            variant="outlined"
            onChange={(event: any) => this.changeHistory(event)}
          >
            {this.renderOptions()}
          </Select>
        </div>
        <Grid>
          <button className="timepicker--button-apply" onClick={this.onApply}>
            Apply
          </button>
        </Grid>
      </>
    );
  }
}
