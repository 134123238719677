import React, { ChangeEvent, FocusEvent, KeyboardEvent, PureComponent } from 'react';

import { TextBoxVariableModel, VariableHide } from '../variable';
import { toVariablePayload } from '../state/types';
import { dispatch } from '../../../store/store';
import { variableAdapters } from '../adapters';
import { changeVariableProp } from '../state/sharedReducer';
import { VariablePickerProps } from '../pickers/types';
import { TextField } from '@material-ui/core';

export interface Props extends VariablePickerProps<TextBoxVariableModel> {}

export class TextBoxVariablePicker extends PureComponent<Props> {
  onQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeVariableProp(toVariablePayload(this.props.variable, { propName: 'query', propValue: event.target.value }))
    );
  };

  onQueryBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (this.props.variable.current.value !== this.props.variable.query) {
      variableAdapters.get(this.props.variable.type).updateOptions(this.props.variable);
    }
  };

  onQueryKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && this.props.variable.current.value !== this.props.variable.query) {
      variableAdapters.get(this.props.variable.type).updateOptions(this.props.variable);
    }
  };

  getLabel = () => {
    const { variable } = this.props;
    return variable.hide === VariableHide.hideLabel ? variable.label || variable.name : undefined;
  };

  render() {
    return (
      <TextField
        size="small"
        variant="outlined"
        className="max-width"
        label={this.getLabel()}
        value={this.props.variable.query}
        onChange={this.onQueryChange}
        onBlur={this.onQueryBlur}
        onKeyDown={this.onQueryKeyDown}
      />
    );
  }
}
