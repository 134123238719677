import React from 'react';
import config from 'app/core/config';

import { updateLocation } from 'app/core/actions';
import { connect } from 'react-redux';
import { StoreState } from 'app/types';
import { PureComponent } from 'react';
import { getBackendSrv } from '@grafana/runtime';
import { hot } from 'react-hot-loader';
import appEvents from 'app/core/app_events';
import { AppEvents } from '@grafana/data';

export interface FormModel {
  corporateEmail: string;
  orgName: string;
  verificationCode: number;
  newPassword: string;
  confirmPassword: string;
}
interface Props {
  routeParams?: any;
  updateLocation?: typeof updateLocation;
  children: (props: {
    getVerificationCode: (pw: string) => void;
    haveVerificationCode: boolean;
    signup: (data: FormModel) => void;
    haveVerificationClick: () => void;
    dontHaveVerificationClick: () => void;
  }) => JSX.Element;
}

interface State {
  haveVerificationCode: boolean;
}

export class RegisterCtrl extends PureComponent<Props, State> {
  result: any = {};
  constructor(props: Props) {
    super(props);
    this.state = {
      haveVerificationCode: false,
    };

    if (config.loginError) {
      appEvents.emit(AppEvents.alertWarning, ['Registration Failed', config.loginError]);
    }
  }

  getVerificationCode = (email: string) => {
    const data = {
      email: email,
      subscribeOnly: true,
    };
    getBackendSrv()
      .put('/api/user/signup', data)
      .then(() => {
        this.haveVerificationClick();
      })
      .catch((err: any) => console.log(err));
  };

  signup = (formModel: FormModel) => {
    getBackendSrv()
      .post('/api/user/signup/step2', formModel)
      .then((result: any) => {
        this.result = result;
        this.toGrafana();
      })
      .catch(() => {
        // this.setState({
        //   isLoggingIn: false,
        // });
      });
  };

  haveVerificationClick = () => {
    this.setState({
      haveVerificationCode: true,
    });
  };

  dontHaveVerificationClick = () => {
    this.setState({
      haveVerificationCode: false,
    });
  };

  toGrafana = () => {
    const params = this.props.routeParams;
    // Use window.location.href to force page reload
    if (params.redirect && params.redirect[0] === '/') {
      window.location.href = config.appSubUrl + params.redirect;
    } else if (this.result.redirectUrl) {
      window.location.href = config.appSubUrl + this.result.redirectUrl;
    } else {
      window.location.href = config.appSubUrl + '/';
    }
  };

  render() {
    const { children } = this.props;
    const { haveVerificationCode } = this.state;
    const { signup, getVerificationCode, haveVerificationClick, dontHaveVerificationClick } = this;

    return (
      <>
        {children({
          signup,
          getVerificationCode,
          haveVerificationCode,
          haveVerificationClick,
          dontHaveVerificationClick,
        })}
      </>
    );
  }
}

export const mapStateToProps = (state: StoreState) => ({
  routeParams: state.location.routeParams,
});

const mapDispatchToProps = { updateLocation };

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(RegisterCtrl));
