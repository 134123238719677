import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Select, MenuItem } from '@material-ui/core';
import { TimeZone, TimeRange, TimeOption } from '@grafana/data';
import { mapOptionToTimeRange } from '../TimePickerContent/mapper';
import getQuarter from 'date-fns/getQuarter';
import set from 'date-fns/set';
import addMonths from 'date-fns/addMonths';
import formatISO from 'date-fns/formatISO';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';

interface Props {
  value: any;
  onApply: (range: TimeRange) => void;
  timeZone?: TimeZone;
}
interface State {
  year: any;
  quarter: any;
}
export default class QuarterRangeComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      year: this.props.value.from,
      quarter: this.getQuarter(this.props.value.from),
    };
    this.onApply = this.onApply.bind(this);
  }

  quarters = [
    { label: 'JFM', value: 0 },
    { label: 'AMJ', value: 1 },
    { label: 'JAS', value: 2 },
    { label: 'OND', value: 3 },
  ];

  getQuarter = (date: any) => {
    return this.quarters[getQuarter(new Date(date)) - 1].value;
  };

  handleYearChange = (year: any | null) => {
    this.setState({
      year: year,
    });
  };

  handleChange = (event: any) => {
    this.setState({ quarter: event.target.value });
  };

  renderOptions() {
    return this.quarters.map((qtr, i) => {
      return (
        <MenuItem value={qtr.value} key={i}>
          {qtr.label}
        </MenuItem>
      );
    });
  }

  onApply = () => {
    const { year, quarter } = this.state;
    const fullYear = new Date(year).getFullYear();
    const fromDate = set(new Date(), { year: fullYear, month: quarter * 3, date: 1, hours: 0, minutes: 0, seconds: 0 });
    const toDate = set(lastDayOfMonth(addMonths(fromDate, 2)), { hours: 23, minutes: 59, seconds: 59 });

    const displayOption: TimeOption = {
      from: formatISO(fromDate),
      to: formatISO(toDate),
      display: quarter,
      section: 1,
      range_type: this.props.value.range_type,
    };
    this.props.onApply(mapOptionToTimeRange(displayOption, this.props.timeZone));
  };

  render() {
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="yyyy"
              size="small"
              views={['year']}
              openTo="year"
              margin="normal"
              id="year-picker-inline"
              label="Year"
              value={this.state.year}
              onChange={this.handleYearChange}
              KeyboardButtonProps={{
                'aria-label': 'change year',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>

        <Select
          className="max-width timepicker-mat-select"
          value={this.state.quarter}
          variant="outlined"
          onChange={this.handleChange}
        >
          {this.renderOptions()}
        </Select>
        <Grid>
          <button className="timepicker--button-apply" onClick={this.onApply}>
            Apply
          </button>
        </Grid>
      </>
    );
  }
}
