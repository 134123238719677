import React from 'react';
import { useMedia } from 'react-use';
import { css } from 'emotion';
import { useTheme, stylesFactory } from '../../../themes';
import { GrafanaTheme, TimeOption, TimeRange, TimeZone } from '@grafana/data';
import { TimePickerTitle } from './TimePickerTitle';
import { mapRangeToTimeOption } from './mapper';
import { getThemeColors } from './colors';
import TimeSelectComponent from './TimePickerSelect';

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  const colors = getThemeColors(theme);

  return {
    container: css`
      display: flex;
      background: ${colors.background};
      box-shadow: 0px 0px 20px ${colors.shadow};
      position: absolute;
      z-index: ${theme.zIndex.modal};
      width: 546px;
      height: fit-content;
      top: 116%;
      margin-left: -145px;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        width: 218px;
        margin-left: 6px;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        width: 264px;
        margin-left: -100px;
      }
    `,
    leftSide: css`
      display: flex;
      flex-direction: column;
      border-right: 1px solid ${colors.border};
      width: 100%;
      overflow: auto;
    `,
    spacing: css`
      margin-top: 16px;
    `,
  };
});

const getFullScreenStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    container: css`
      padding-top: 9px;
      padding-left: 11px;
      padding-right: 11px;
    `,
    title: css`
      margin-bottom: 11px;
    `,
    recent: css`
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    `,
  };
});

interface Props {
  value: TimeRange;
  onChange: (timeRange: TimeRange) => void;
  timeZone?: TimeZone;
  quickOptions?: TimeOption[];
  otherOptions?: TimeOption[];
  history?: TimeRange[];
}

interface PropsWithScreenSize extends Props {
  isFullscreen: boolean;
}

interface FormProps extends Omit<Props, 'history'> {
  historyOptions?: TimeOption[];
}

export const TimePickerContentWithScreenSize: React.FC<PropsWithScreenSize> = props => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const historyOptions = mapToHistoryOptions(props.history, props.timeZone);

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <FullScreenForm {...props} historyOptions={historyOptions} />
      </div>
    </div>
  );
};

export const TimePickerContent: React.FC<Props> = props => {
  const theme = useTheme();
  const isFullscreen = useMedia(`(min-width: ${theme.breakpoints.lg})`);

  return <TimePickerContentWithScreenSize {...props} isFullscreen={isFullscreen} />;
};

const FullScreenForm: React.FC<FormProps> = props => {
  const theme = useTheme();
  const styles = getFullScreenStyles(theme);

  return (
    <>
      <div className={styles.container + ' content-timepicker'}>
        <div className={styles.title}>
          <TimePickerTitle>Absolute time range</TimePickerTitle>
        </div>
        <TimeSelectComponent
          value={props.value}
          timeZone={props.timeZone}
          onApply={props.onChange}
          quickOptions={props.quickOptions || []}
          otherOptions={props.otherOptions || []}
          history={props.historyOptions || []}
        />
      </div>
    </>
  );
};

function mapToHistoryOptions(ranges?: TimeRange[], timeZone?: TimeZone): TimeOption[] {
  if (!Array.isArray(ranges) || ranges.length === 0) {
    return [];
  }
  return ranges.slice(ranges.length - 4).map(range => mapRangeToTimeOption(range, timeZone));
}
