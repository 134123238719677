import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DateTimeVariableModel, VariableHide, VariableOption } from '../variable';
import { EMPTY_UUID, getInstanceState, VariablePayload } from '../state/types';
import { initialVariablesState, VariablesState } from '../state/variablesReducer';

export const initialDateTimeVariableModelState: DateTimeVariableModel = {
  uuid: EMPTY_UUID,
  global: false,
  index: -1,
  type: 'datetime',
  name: '',
  label: '',
  hide: VariableHide.dontHide,
  query: '',
  current: {} as VariableOption,
  options: [],
  skipUrlSync: false,
  initLock: null,
};

export const dateTimeVariableSlice = createSlice({
  name: 'templating/datetime',
  initialState: initialVariablesState,
  reducers: {
    createDateTimeOptions: (state: VariablesState, action: PayloadAction<VariablePayload>) => {
      const instanceState = getInstanceState<DateTimeVariableModel>(state, action.payload.uuid!);
      instanceState.options = [
        { text: instanceState.query.trim(), value: instanceState.query.trim(), selected: false },
      ];
      instanceState.current = instanceState.options[0];
    },
  },
});

export const dateTimeVariableReducer = dateTimeVariableSlice.reducer;

export const { createDateTimeOptions } = dateTimeVariableSlice.actions;
