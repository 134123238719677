import cloneDeep from 'lodash/cloneDeep';

import { DateTimeVariableModel } from '../variable';
import { initialDateTimeVariableModelState, dateTimeVariableReducer } from './reducer';
import { dispatch } from '../../../store/store';
import { setOptionAsCurrent, setOptionFromUrl } from '../state/actions';
import { VariableAdapter } from '../adapters';
import { DateTimeVariablePicker } from './DateTimeVariablePicker';
import { DateTimeVariableEditor } from './DateTimeVariableEditor';
import { updateDateTimeVariableOptions } from './actions';
import { toVariableIdentifier } from '../state/types';

export const createDateTimeVariableAdapter = (): VariableAdapter<DateTimeVariableModel> => {
  return {
    description: 'Define a datetime variable, where users can enter any arbitrary datetime',
    label: 'DateTime',
    initialState: initialDateTimeVariableModelState,
    reducer: dateTimeVariableReducer,
    picker: DateTimeVariablePicker,
    editor: DateTimeVariableEditor,
    dependsOn: (variable, variableToTest) => {
      return false;
    },
    setValue: async (variable, option, emitChanges = false) => {
      await dispatch(setOptionAsCurrent(toVariableIdentifier(variable), option, emitChanges));
    },
    setValueFromUrl: async (variable, urlValue) => {
      await dispatch(setOptionFromUrl(toVariableIdentifier(variable), urlValue));
    },
    updateOptions: async variable => {
      await dispatch(updateDateTimeVariableOptions(toVariableIdentifier(variable)));
    },
    getSaveModel: variable => {
      const { index, uuid, initLock, global, ...rest } = cloneDeep(variable);
      return rest;
    },
    getValueForUrl: variable => {
      return variable.current.value;
    },
  };
};
