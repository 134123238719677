import React, { PureComponent } from 'react';
import { Tooltip, ClickOutsideWrapper } from '@grafana/ui';
import { SearchDropdown } from './SearchDropdown';
import { CoreEvents } from 'app/types';
import { appEvents } from 'app/core/core';

export class NavSearch extends PureComponent {
  isOpen = false;
  constructor(props: any) {
    super(props);
  }
  closeSearchDropdown = () => {
    appEvents.emit(CoreEvents.toggleSearchHiddenDropdown);
    this.isOpen = false;
  };

  openSearchDropdown = () => {
    if (this.isOpen) {
      this.closeSearchDropdown();
    } else {
      appEvents.emit(CoreEvents.toggleSearchDropdown);
      this.isOpen = true;
    }
  };

  render() {
    return (
      <ClickOutsideWrapper onClick={this.closeSearchDropdown}>
        <div>
          <div className="refresh-picker-buttons">
            <Tooltip placement="top" content="search">
              <button className="btn btn--radius-right-0 navbar-button" onClick={this.openSearchDropdown}>
                <i className="gicon gicon-nav-search" />
              </button>
            </Tooltip>
          </div>
          <SearchDropdown />
        </div>
      </ClickOutsideWrapper>
    );
  }
}
