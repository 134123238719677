// Libraries
import _ from 'lodash';
import { FloatProperty } from 'csstype';
import React, { PureComponent, CSSProperties } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { StoreState } from 'app/types';
import { getVariableClones } from 'app/features/templating/state/selectors';

// Components
import { PanelOptionsGroup, PanelOptionsGrid, Switch } from '@grafana/ui';
import { TextField, Typography, IconButton, Button, MenuItem, Grid } from '@material-ui/core';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';

// Types
import { PanelEditorProps } from '@grafana/data';
import { VariableModel } from 'app/features/templating/variable';
import { VariableListOptions, ElementModel, DefaultElement, ElementMap } from './types';

interface OwnProps extends PanelEditorProps<VariableListOptions> {}
interface ConnectedProps {
  variables: VariableModel[];
}
type Props = OwnProps & ConnectedProps;
interface State {
  expanded: string | false;
}
export class VariableListEditorUnConnected extends PureComponent<Props, State> {
  state: State = {
    expanded: false,
  };

  onElementChange = (element: ElementModel, index: number, what: string, event: any) => {
    const { elements } = this.props.options;
    if (elements[index].name === element.name) {
      (elements[index] as any)[what] = event.target.value;
    }
    this.props.onOptionsChange({ ...this.props.options, elements: elements });
  };

  onAddElement = () => {
    const { elements } = this.props.options;
    const element = _.cloneDeep(DefaultElement);
    element.name = '';

    for (let i = 0; i < 10; i++) {
      let name =
        'element-' +
        Math.random()
          .toString()
          .substring(2, 5);
      if (!elements.filter(e => e.name === name).pop()) {
        element.name = name;
        break;
      }
    }

    if (element.name) {
      elements.push(element);
      this.props.onOptionsChange({ ...this.props.options, elements: elements });
    } else {
      // TODO: notify
    }
  };

  selectElement = (name: string) => (_: any, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? name : false });
  };

  moveElement = (index: number, step: number) => (event: any) => {
    event.stopPropagation();
    const { elements } = this.props.options;
    (_ as any).move(elements, index, index + step);
    this.props.onOptionsChange({ ...this.props.options, elements: elements });
  };

  removeElement = (element: ElementModel) => (event: any) => {
    event.stopPropagation();
    const { elements } = this.props.options;
    _.remove(elements, element);
    this.props.onOptionsChange({ ...this.props.options, elements: elements });
  };

  render() {
    const { options, variables } = this.props;
    const { expanded } = this.state;

    const styles_add: CSSProperties = {
      float: 'right' as FloatProperty,
      margin: '12px 0px',
    };

    const styles_field: CSSProperties = {
      marginBottom: '8px',
    };

    const styles_move_up: CSSProperties = {
      padding: '0',
      position: 'absolute',
      right: '105px',
    };

    const styles_move_down: CSSProperties = {
      padding: '0',
      position: 'absolute',
      right: '80px',
    };

    const styles_remove: CSSProperties = {
      padding: '0',
      position: 'absolute',
      right: '55px',
    };

    return (
      <PanelOptionsGrid>
        <PanelOptionsGroup title="Elements">
          <>
            {options.elements.map((element: ElementModel, index: number) => {
              return (
                <ExpansionPanel
                  key={element.name}
                  expanded={expanded === element.name}
                  onChange={this.selectElement(element.name)}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{element.name}</Typography>
                    {index !== 0 && (
                      <IconButton onClick={this.moveElement(index, -1)} style={styles_move_up}>
                        <ArrowUpwardIcon />
                      </IconButton>
                    )}
                    {index !== options.elements.length - 1 && (
                      <IconButton onClick={this.moveElement(index, 1)} style={styles_move_down}>
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={this.removeElement(element)} style={styles_remove}>
                      <DeleteIcon />
                    </IconButton>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container>
                      <TextField
                        select
                        label="Type"
                        value={element.type}
                        size="small"
                        className="max-width"
                        style={styles_field}
                        color="secondary"
                        onChange={(e: any) => this.onElementChange(element, index, 'type', e)}
                      >
                        {Object.keys(ElementMap).map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select={element.type === 'Variable'}
                        label="Name"
                        value={element.name}
                        size="small"
                        className="max-width"
                        style={styles_field}
                        color="secondary"
                        onChange={(e: any) => this.onElementChange(element, index, 'name', e)}
                      >
                        {element.type === 'Variable' &&
                          variables.map(option => (
                            <MenuItem key={option.name} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      {element.type === 'Variable' && (
                        <Switch
                          label="Label"
                          checked={element.label}
                          onChange={() =>
                            this.onElementChange(element, index, 'label', { target: { value: !element.label } })
                          }
                        />
                      )}
                      {element.type !== 'Variable' && (
                        <TextField
                          multiline
                          label="Props"
                          value={element.props}
                          size="small"
                          className="max-width"
                          style={styles_field}
                          color="secondary"
                          onChange={(e: any) => this.onElementChange(element, index, 'props', e)}
                        />
                      )}
                      {element.type !== 'Variable' && (
                        <TextField
                          multiline
                          label="HTML"
                          value={element.html}
                          size="small"
                          className="max-width"
                          style={styles_field}
                          color="secondary"
                          onChange={(e: any) => this.onElementChange(element, index, 'html', e)}
                        />
                      )}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
            <Button
              aria-label="add element"
              style={styles_add}
              onClick={this.onAddElement}
              color="secondary"
              startIcon={<AddIcon />}
              variant="contained"
            >
              Add Element
            </Button>
          </>
        </PanelOptionsGroup>
        <PanelOptionsGroup title="Actions">
          <TextField
            select
            label="Submit"
            value={options.submit}
            size="small"
            style={styles_field}
            color="secondary"
            className="max-width"
            onChange={(e: any) => this.props.onOptionsChange({ ...this.props.options, submit: e.target.value })}
          >
            {options.elements.map(option => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="URL"
            value={options.url}
            size="small"
            style={styles_field}
            color="secondary"
            className="max-width"
            onChange={(e: any) => this.props.onOptionsChange({ ...this.props.options, url: e.target.value })}
          />
        </PanelOptionsGroup>
      </PanelOptionsGrid>
    );
  }
}

const mapStateToProps: MapStateToProps<ConnectedProps, OwnProps, StoreState> = state => ({
  variables: getVariableClones(state, false),
});

export const VariableListEditor = connect(mapStateToProps)(VariableListEditorUnConnected);
VariableListEditor.displayName = 'VariableListEditor';
