import React, { FC } from 'react';
import _ from 'lodash';
import SignIn from './SignIn';
import BottomNavLinks from './BottomNavLinks';
import { contextSrv } from 'app/core/services/context_srv';
import config from '../../config';
import { NavModelItem } from '@grafana/data';

export interface Props {
  onMouseEnter: any;
  onMouseLeave: any;
  openDropdown: any;
}

const BottomSection: FC<Props> = props => {
  const { onMouseEnter, onMouseLeave, openDropdown } = props;
  const navTree: NavModelItem[] = _.cloneDeep(config.bootData.navTree);
  const bottomNav: NavModelItem[] = _.filter(
    navTree,
    item => item.id === 'toggle' || item.id === 'profile' || item.id === 'help'
  );
  const isSignedIn = contextSrv.isSignedIn;
  const user = contextSrv.user;

  if (user && user.orgCount > 1) {
    const profileNode: any = _.find(bottomNav, { id: 'profile' });
    if (profileNode) {
      profileNode.showOrgSwitcher = true;
    }
  }

  return (
    <div className="sidemenu__bottom">
      {!isSignedIn && <SignIn />}
      {bottomNav.map((link, index) => {
        return (
          <BottomNavLinks
            link={link}
            user={user}
            key={`${link.url}-${index}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            openDropdown={openDropdown}
          />
        );
      })}
    </div>
  );
};

export default BottomSection;
