import _ from 'lodash';
import { QueryPartDef, QueryPart } from 'app/core/components/query_part/query_part';

const alertQueryDef = new QueryPartDef({
  type: 'query',
  params: [
    { name: 'queryRefId', type: 'string', dynamicLookup: true },
    {
      name: 'from',
      type: 'string',
      options: ['10s', '1m', '5m', '10m', '15m', '1h', '24h', '48h'],
    },
    { name: 'to', type: 'string', options: ['now', 'now-1m', 'now-5m', 'now-10m', 'now-1h'] },
  ],
  defaultParams: ['#A', '15m', 'now', 'avg'],
});

const conditionTypes = [{ text: 'Query', value: 'query' }];

const evalFunctions = [
  { text: 'IS ABOVE', value: 'gt' },
  { text: 'IS BELOW', value: 'lt' },
  { text: 'IS OUTSIDE RANGE', value: 'outside_range' },
  { text: 'IS WITHIN RANGE', value: 'within_range' },
  { text: 'HAS NO VALUE', value: 'no_value' },
];

const evalOperators = [
  { text: 'OR', value: 'or' },
  { text: 'AND', value: 'and' },
];

const reducerTypes = [
  { text: 'avg()', value: 'avg' },
  { text: 'min()', value: 'min' },
  { text: 'max()', value: 'max' },
  { text: 'sum()', value: 'sum' },
  { text: 'count()', value: 'count' },
  { text: 'last()', value: 'last' },
  { text: 'median()', value: 'median' },
  { text: 'diff()', value: 'diff' },
  { text: 'diff_abs()', value: 'diff_abs' },
  { text: 'percent_diff()', value: 'percent_diff' },
  { text: 'percent_diff_abs()', value: 'percent_diff_abs' },
  { text: 'count_non_null()', value: 'count_non_null' },
];

const stateDef = {
  Alerting: { color: '#D51434', icon: 'icon-gf icon-gf-critical' },
  HH: { color: '#D51434', icon: 'icon-gf icon-gf-critical' },
  LL: { color: '#D51434', icon: 'icon-gf icon-gf-critical' },
  H: { color: '#F9304A', icon: 'icon-gf icon-gf-critical' },
  L: { color: '#F9304A', icon: 'icon-gf icon-gf-critical' },
  'No Data': { color: '#C5C5C5', icon: 'fa fa-question' },
  'Keep Last State': { color: 'N/A' },
  OK: { color: '#60BF18', icon: 'icon-gf icon-gf-online' },
  Paused: { color: '#C5C5C5', icon: 'fa fa-pause' },
};

const defaultTags = {
  __priority__: '2',
  __unit__: '',
};

const priorityOptions = {
  1: '1 - High',
  2: '2 - Medium',
  3: '3 - Low',
};

function createReducerPart(model: any) {
  const def = new QueryPartDef({ type: model.type, defaultParams: [] });
  return new QueryPart(model, def);
}

function getStateDisplayModel(stateDef: any, state: string) {
  return {
    text: state,
    iconClass: stateDef[state]?.icon || 'icon-gf icon-gf-critical',
    stateColor: stateDef[state]?.color || '#C5C5C5',
  };
}

function joinEvalMatches(matches: any, separator: string) {
  return _.reduce(
    matches,
    (res, ev) => {
      if (ev.metric !== undefined && ev.value !== undefined) {
        res.push(ev.metric + '=' + ev.value);
      }

      // For backwards compatibility . Should be be able to remove this after ~2017-06-01
      if (ev.Metric !== undefined && ev.Value !== undefined) {
        res.push(ev.Metric + '=' + ev.Value);
      }

      return res;
    },
    []
  ).join(separator);
}

function getAlertAnnotationInfo(ah: any) {
  // backward compatibility, can be removed in grafana 5.x
  // old way stored evalMatches in data property directly,
  // new way stores it in evalMatches property on new data object

  if (_.isArray(ah.data)) {
    return joinEvalMatches(ah.data, ', ');
  } else if (_.isArray(ah.data.evalMatches)) {
    return joinEvalMatches(ah.data.evalMatches, ', ');
  }

  if (ah.data.error) {
    return 'Error: ' + ah.data.error;
  }

  return '';
}

export default {
  alertQueryDef: alertQueryDef,
  conditionTypes: conditionTypes,
  evalFunctions: evalFunctions,
  evalOperators: evalOperators,
  stateDef: stateDef,
  reducerTypes: reducerTypes,
  createReducerPart: createReducerPart,
  getAlertAnnotationInfo: getAlertAnnotationInfo,
  getStateDisplayModel: getStateDisplayModel,
  defaultTags: defaultTags,
  priorityOptions: priorityOptions,
};
