import React, { PureComponent } from 'react';
import { VariableOption, VariableWithMultiSupport } from '../../variable';
import { VariablePickerProps } from '../types';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import { variableAdapters } from '../../adapters';
import { ALL_VARIABLE_TEXT, ALL_VARIABLE_VALUE } from '../../state/types';

interface Props extends VariablePickerProps<VariableWithMultiSupport> {}

export class OptionsPicker extends PureComponent<Props> {
  onToggleOption = async (event: any, option: VariableOption | VariableOption[] | null) => {
    const { variable } = this.props;
    if (variable.multi) {
      let _option = option as VariableOption[];
      let all = false;
      if (variable.includeAll) {
        const selected = variable.options.filter(o => o.selected);
        const all_in_old = !!selected.filter((m: VariableOption) => m.value === ALL_VARIABLE_VALUE).pop();
        const all_in_new = !!_option.filter((m: VariableOption) => m.value === ALL_VARIABLE_VALUE).pop();
        const unselected = variable.options.length - _option.length;
        if (!all_in_old && all_in_new) {
          all = true;
        } else if (!all_in_old && !all_in_new && unselected === 1) {
          all = true;
        } else if (all_in_old && all_in_new && unselected === 0) {
          all = true;
        } else if (all_in_old && !all_in_new && unselected === 1) {
          _option = [];
        }
      }
      if (all) {
        variable.options = variable.options.map((o: VariableOption) => {
          o.selected = true;
          return o;
        });
      } else {
        variable.options = variable.options.map((o: VariableOption) => {
          if (o.value !== ALL_VARIABLE_VALUE) {
            o.selected = !!_option.filter((m: VariableOption) => m.value === o.value).pop();
          }
          return o;
        });
      }
      variable.current = {
        selected: true,
        text: all
          ? ALL_VARIABLE_TEXT
          : variable.options.filter((m: VariableOption) => m.selected).map((m: VariableOption) => m.text as string),
        value: all
          ? [ALL_VARIABLE_VALUE]
          : variable.options.filter((m: VariableOption) => m.selected).map((m: VariableOption) => m.value as string),
      };
    } else {
      let _option = option as VariableOption;
      if (!_option || !_option.text) {
        if (variable.options.length === 0) {
          return;
        }
        _option = variable.options[0];
      }
      variable.options = variable.options.map((o: VariableOption) => {
        o.selected = false;
        if (o.value === _option.value) {
          o.selected = true;
        }
        return o;
      });
      _option.selected = true;
      variable.current = _option;
    }

    const adapter = variableAdapters.get(variable.type);
    await adapter.setValue(variable, variable.current, true);
  };

  getSelectedOptions = () => {
    const { variable } = this.props;
    if (variable.multi) {
      let options = variable.options.filter(o => o.selected);
      const all = !!options.filter((m: VariableOption) => m.value === ALL_VARIABLE_VALUE).pop();
      if (all) {
        variable.options = variable.options.map((o: VariableOption) => {
          o.selected = true;
          return o;
        });
        options = variable.options;
      }
      return options;
    } else {
      return variable.current;
    }
  };

  renderOption = (option: VariableOption, state: any) => {
    const { variable } = this.props;
    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    return (
      <React.Fragment>
        {variable.multi && (
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={state.selected} />
        )}
        {option.text}
      </React.Fragment>
    );
  };

  renderInput = (params: any) => {
    return <TextField {...params} variant="outlined" color="secondary" />;
  };

  render() {
    const { variable } = this.props;

    const props: any = {
      disableClearable: true,
      disableListWrap: true,
      size: 'small',
      value: this.getSelectedOptions(),
      onChange: this.onToggleOption,
      options: variable.options,
      getOptionLabel: (option: VariableOption) => option.text as string,
      filterOptions: (options: VariableOption) => options,
      renderOption: this.renderOption,
      renderInput: this.renderInput,
    };

    if (variable.multi) {
      props.disableCloseOnSelect = true;
      props.multiple = true;
      return <Autocomplete {...props} />;
    } else if (props.options.length === 1) {
      return this.renderInput({
        fullWidth: true,
        size: props.size,
        value: props.value.text,
      });
    } else {
      return <Autocomplete {...props} />;
    }
  }
}
