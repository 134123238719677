import React, { FC } from 'react';
import SideMenuDropDown from './SideMenuDropDown';

export interface Props {
  link: any;
  onMouseEnter: any;
  onMouseLeave: any;
  openDropdown: any;
}

const TopSectionItem: FC<Props> = props => {
  const { link, onMouseEnter, onMouseLeave, openDropdown } = props;
  return (
    <div
      className="sidemenu-item dropdown"
      id={link.text}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={openDropdown}
    >
      <a className="sidemenu-link" href={link.url} target={link.target}>
        <span className="icon-circle sidemenu-icon">
          <i className={link.icon} />
          {link.img && <img src={link.img} />}
        </span>
        <span className={'sidemenu-titles ' + (link.children && 'after-title')}>{link.text}</span>
      </a>
      {/* {link.children && (

      )} */}
      <SideMenuDropDown link={link} />
    </div>
  );
};

export default TopSectionItem;
