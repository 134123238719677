import React, { PureComponent } from 'react';
import appEvents from '../../app_events';
import { User } from '../../services/context_srv';
import { NavModelItem } from '@grafana/data';
import { CoreEvents } from 'app/types';
import { OrgSwitcher } from '../OrgSwitcher';
import { getFooterLinks } from '../Footer/Footer';

export interface Props {
  link: NavModelItem;
  user: User;
  onMouseEnter: any;
  onMouseLeave: any;
  openDropdown: any;
}

interface State {
  showSwitcherModal: boolean;
}

class BottomNavLinks extends PureComponent<Props, State> {
  state: State = {
    showSwitcherModal: false,
  };

  onOpenShortcuts = () => {
    appEvents.emit(CoreEvents.showModal, {
      templateHtml: '<help-modal></help-modal>',
    });
  };

  onOpenIcons = () => {
    appEvents.emit(CoreEvents.showModal, {
      templateHtml: '<icons-modal></icons-modal>',
    });
  };

  toggleSwitcherModal = () => {
    this.setState(prevState => ({
      showSwitcherModal: !prevState.showSwitcherModal,
    }));
  };

  render() {
    const { link, user } = this.props;
    const { showSwitcherModal } = this.state;

    let children = link.children || [];

    if (link.id === 'help') {
      children = getFooterLinks();
    }

    return (
      <div
        className="sidemenu-item dropdown dropup"
        id={link.text}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.props.openDropdown}
      >
        <a href={link.url} className="sidemenu-link" target={link.target}>
          <span className="icon-circle sidemenu-icon">
            {link.icon && <i className={link.icon} />}
            {link.img && <img src={link.img} />}
          </span>
          <span className={'sidemenu-titles ' + (children && 'after-title')}> &nbsp;{link.text}</span>
        </a>
        {children && (
          <ul className="dropdown-menu dropdown-menu--sidemenu" role="menu">
            {link.subTitle && (
              <li className="sidemenu-subtitle">
                <span className="sidemenu-item-text">{link.subTitle}</span>
              </li>
            )}
            {link.showOrgSwitcher && (
              <li className="sidemenu-org-switcher">
                <a onClick={this.toggleSwitcherModal}>
                  <div>
                    <div className="sidemenu-org-switcher__org-name">{user.orgName}</div>
                    <div className="sidemenu-org-switcher__org-current">Current Org:</div>
                  </div>
                  <div className="sidemenu-org-switcher__switch">
                    <i className="fa fa-fw fa-random" />
                    Switch
                  </div>
                </a>
              </li>
            )}

            {showSwitcherModal && <OrgSwitcher onDismiss={this.toggleSwitcherModal} />}

            {children.map((child, index) => {
              return (
                <li className="sidemenu-dropdown-child-link" key={`${child.text}-${index}`}>
                  <a href={child.url} target={child.target} rel="noopener">
                    {child.icon && <i className={child.icon} />}
                    {child.text}
                  </a>
                </li>
              );
            })}

            {link.id === 'help' && (
              <li key="keyboard-shortcuts">
                <a onClick={() => this.onOpenShortcuts()}>
                  <i className="fa fa-keyboard-o" /> Keyboard shortcuts
                </a>
              </li>
            )}

            {link.id === 'help' && (
              <li key="sidemenu-icons">
                <a onClick={() => this.onOpenIcons()}>
                  <i className="gicon gicon-dashboard" /> Sidemenu Icons
                </a>
              </li>
            )}

            <li className="side-menu-header">
              <span className="sidemenu-item-text">{link.text}</span>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

export default BottomNavLinks;
