import React, { PureComponent, SyntheticEvent, ChangeEvent } from 'react';

interface Props {
  onResetPassword: (email: string) => void;
  onBack: () => void;
}
interface State {
  username: string;
  valid: boolean;
}
export class ForgotPassword extends PureComponent<Props, State> {
  private userInput: HTMLInputElement;
  constructor(props: Props) {
    super(props);
    this.state = {
      username: '',
      valid: false,
    };
  }
  focus() {
    this.userInput.focus();
  }

  onResetPassword = (e: SyntheticEvent) => {
    e.preventDefault();
    if (this.state.valid) {
      this.props.onResetPassword(this.state.username);
    }
  };
  myChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const nam = e.target.name;
    const val = e.target.value;
    this.setState({ [nam]: val } as any, () => {
      this.validateRequiredField();
    });
  };
  validateRequiredField() {
    this.setState({
      valid: this.state.username ? true : false,
    });
  }

  render() {
    return (
      <div className="login-inner-box" id="change-password-view">
        <span className="login-header-lg fnt-24">Reset Password</span>
        <br />
        <br />
        <div className="login-form">
          <input
            type="text"
            id="reset"
            name="username"
            className="gf-form-input login-form-input"
            required
            placeholder="email or username"
            onChange={this.myChangeHandler}
            ref={input => {
              this.userInput = input;
            }}
          />
        </div>

        <div className="login-button-group ">
          <button
            type="submit"
            className={`btn btn-medium p-x-2 ${this.state.valid ? 'btn-primary' : 'btn-inverse'}`}
            disabled={!this.state.valid}
            onClick={this.onResetPassword}
          >
            Reset Password
          </button>
          <button className="btn btn-medium p-x-2 btn-primary" onClick={this.props.onBack}>
            Back
          </button>
        </div>
      </div>
    );
  }
}
