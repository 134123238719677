import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Select, MenuItem } from '@material-ui/core';
import { TimeRange, TimeZone } from '@grafana/data';
import { mapStringsToTimeRange } from '../TimePickerContent/mapper';
import format from 'date-fns/format';
import getHours from 'date-fns/getHours';
import set from 'date-fns/set';
import addDays from 'date-fns/addDays';

interface Props {
  value: any;
  onApply: (range: TimeRange) => void;
  roundup?: boolean;
  timeZone?: TimeZone;
}
interface State {
  selectedDate: any;
  shift: string;
}
export default class ShiftRangeComponent extends Component<Props, State> {
  shifts = [
    { label: 'Shift 1', value: 'shift-1' },
    { label: 'Shift 2', value: 'shift-2' },
    { label: 'Shift 3', value: 'shift-3' },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedDate: this.props.value.from,
      shift: this.setShift(),
    };
    this.onApply = this.onApply.bind(this);
  }

  setShift = () => {
    const { value } = this.props;
    if (getHours(new Date(value.from)) >= 0 && getHours(new Date(value.from)) <= 7) {
      return 'shift-1';
    } else if (getHours(new Date(value.from)) >= 8 && getHours(new Date(value.from)) < 14) {
      return 'shift-2';
    } else {
      return 'shift-3';
    }
  };

  handleDateChange = (date: Date | null) => {
    this.setState({
      selectedDate: date,
    });
  };

  renderOptions() {
    return this.shifts.map((type, i) => {
      return (
        <MenuItem key={i} value={type.value}>
          {type.label}
        </MenuItem>
      );
    });
  }

  changeRangeType = (event: any) => {
    this.setState({
      shift: event.target.value,
    });
  };

  onApply = () => {
    const { selectedDate, shift } = this.state;
    let from = selectedDate;
    let to = selectedDate;
    if (shift === 'shift-1') {
      from = set(new Date(from), { hours: 0, minutes: 0, seconds: 0 });
      to = set(new Date(to), { hours: 7, minutes: 59, seconds: 59 });
    } else if (shift === 'shift-2') {
      from = set(new Date(from), { hours: 8, minutes: 0, seconds: 0 });
      to = set(addDays(new Date(to), 1), { hours: 15, minutes: 59, seconds: 59 });
    } else if (shift === 'shift-3') {
      from = set(new Date(from), { hours: 16, minutes: 0, seconds: 0 });
      to = set(new Date(to), { hours: 23, minutes: 59, seconds: 59 });
    } else {
      return;
    }
    from = format(from, 'yyyy-MM-dd HH:mm:ss');
    to = format(to, 'yyyy-MM-dd HH:mm:ss');
    this.props.onApply(
      mapStringsToTimeRange(from, to, this.props.roundup, this.props.timeZone, this.props.value.range_type)
    );
  };

  render() {
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              size="small"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={this.state.selectedDate}
              onChange={this.handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Select
          className="max-width timepicker-mat-select"
          value={this.state.shift}
          variant="outlined"
          onChange={(event: any) => this.changeRangeType(event)}
        >
          {this.renderOptions()}
        </Select>
        <Grid>
          <button className="timepicker--button-apply" onClick={this.onApply}>
            Apply
          </button>
        </Grid>
      </>
    );
  }
}
