import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { TimeRange, TimeZone } from '@grafana/data';
import { mapStringsToTimeRange } from '../TimePickerContent/mapper';

interface Props {
  value: TimeRange;
  onApply: (range: TimeRange) => void;
  timeZone?: TimeZone;
  roundup?: boolean;
}
interface State {
  fromDate: any;
  fromTime: any;
  toDate: any;
  toTime: any;
}
export default class TimeRangeComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fromDate: props.value.from.format(),
      fromTime: props.value.from.format(),
      toDate: props.value.to.format(),
      toTime: props.value.to.format(),
    };
    this.onApply = this.onApply.bind(this);
  }

  handleDateChange = (date: Date | null, dateFor: string) => {
    this.setState({ [dateFor]: date } as any);
  };

  onApply = () => {
    let { fromDate, toDate, fromTime, toTime } = this.state;
    fromDate = format(new Date(fromDate), 'yyyy-MM-dd');
    toDate = format(new Date(toDate), 'yyyy-MM-dd');
    fromTime = format(new Date(fromTime), 'HH:mm:ss');
    toTime = format(new Date(toTime), 'HH:mm:ss');
    const from = fromDate + 'T' + fromTime;
    const to = toDate + 'T' + toTime;
    this.props.onApply(
      mapStringsToTimeRange(from, to, this.props.roundup, this.props.timeZone, this.props.value.range_type)
    );
  };

  render() {
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              autoOk
              disableToolbar
              className="half-width"
              variant="inline"
              format="dd/MM/yyyy"
              size="small"
              margin="normal"
              inputVariant="outlined"
              id="date-picker-inline"
              label="Date From"
              value={this.state.fromDate}
              onChange={date => this.handleDateChange(date, 'fromDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              className="half-width"
              size="small"
              label="Time From"
              ampm={false}
              format="HH:mm:ss"
              inputVariant="outlined"
              value={this.state.fromTime}
              onChange={date => this.handleDateChange(date, 'fromTime')}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
            <KeyboardDatePicker
              autoOk
              disableToolbar
              className="half-width"
              variant="inline"
              size="small"
              format="dd/MM/yyyy"
              margin="normal"
              inputVariant="outlined"
              id="to-date-picker-inline"
              label="Date To"
              value={this.state.toDate}
              onChange={date => this.handleDateChange(date, 'toDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="to-time-picker"
              className="half-width"
              size="small"
              label="Time To"
              ampm={false}
              format="HH:mm:ss"
              inputVariant="outlined"
              value={this.state.toTime}
              onChange={date => this.handleDateChange(date, 'toTime')}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid>
          <button className="timepicker--button-apply" onClick={this.onApply}>
            Apply
          </button>
        </Grid>
      </>
    );
  }
}
