// Libraries
import React, { PureComponent } from 'react';

// Utils & Services
import { config } from 'app/core/config';

// Types
import { CardsPanelOptions } from './types';
import { FormattedValueDisplay } from '@grafana/ui';

import { PanelProps, getFieldDisplayValues } from '@grafana/data';

export class CardsPanel extends PureComponent<PanelProps<CardsPanelOptions>> {
  render() {
    const { data, options, replaceVariables } = this.props;

    const values = getFieldDisplayValues({
      ...options,
      replaceVariables,
      theme: config.theme,
      data: data.series,
      autoMinMax: true,
    });

    let hasTitle = false;
    let hasDelta = false;
    for (const value of values) {
      if (value.display.title) {
        hasTitle = true;
        if (options.showChange) {
          if (options.showChangeFilter) {
            if (value.display.title.match(new RegExp(options.showChangeFilter, 'g'))) {
              hasDelta = true;
            }
          } else {
            hasDelta = true;
          }
        }
      }
    }

    let h_text = '100%';
    let h_title = '0%';
    let h_change = '0%';
    if (hasTitle && !hasDelta) {
      h_text = '60%';
      h_title = '40%';
      h_change = '0%';
    } else if (hasTitle && hasDelta) {
      h_text = '40%';
      h_title = '30%';
      h_change = '30%';
    }

    let inputClass = 'gf-form-input';
    if (hasDelta && !options.disableSubmit) {
      inputClass += ' variable-form-input';
    }

    return (
      <ol className="cards-panel">
        {values.map(value => {
          const valueClass = 'cards-panel-item__value ' + value.display.title;
          return (
            <li className="cards-panel-item" key={value.display.title}>
              <div className="cards-panel-item__body">
                <div className={valueClass} style={{ height: h_text, color: options.color || '#444446' }}>
                  <FormattedValueDisplay value={value.display} style={{ fontSize: '46px' }} />
                </div>
                {hasTitle && (
                  <div className="cards-panel-item__name" style={{ height: h_title }}>
                    <div>
                      <p>{value.display.title}</p>
                    </div>
                  </div>
                )}
                {hasDelta && value.display.title.match(new RegExp(options.showChangeFilter, 'g')) && (
                  <div className="cards-panel-item__change" style={{ height: h_change }}>
                    <div>
                      <p>Change to</p>
                      <input name={value.display.title} className={inputClass} placeholder={value.display.text} />
                    </div>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    );
  }
}
