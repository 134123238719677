import clsx from 'clsx';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import endOfWeek from 'date-fns/endOfWeek';
import React, { PureComponent } from 'react';
import startOfWeek from 'date-fns/startOfWeek';
import isWithinInterval from 'date-fns/isWithinInterval';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createStyles } from '@material-ui/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton, withStyles, Grid } from '@material-ui/core';
import { TimeZone, TimeRange } from '@grafana/data';
import { mapStringsToTimeRange } from '../TimePickerContent/mapper';
import set from 'date-fns/set';
import formatISO from 'date-fns/formatISO';

interface Props {
  value: TimeRange;
  onApply: (range: TimeRange) => void;
  timeZone?: TimeZone;
  roundup?: boolean;
  classes?: any;
}
interface State {
  selectedDate: any;
  year: any;
}

class WeekRangeComponent extends PureComponent<Props, State> {
  sDate: any;
  eDate: any;

  constructor(props: Props) {
    super(props);
    this.formatWeekSelectLabel(this.props.value.from, undefined);
    this.state = {
      selectedDate: this.props.value.from.format(),
      year: this.props.value.from.format(),
    };
    this.sDate = startOfWeek(new Date(this.props.value.from.toDate()));
    this.eDate = endOfWeek(new Date(this.props.value.from.toDate()));
    this.onApply = this.onApply.bind(this);
  }

  handleWeekChange = (date: Date) => {
    this.setState({ selectedDate: startOfWeek(date) });
  };

  formatWeekSelectLabel = (date: any, invalidLabel: any) => {
    const dateClone = Date.parse(date);

    return dateClone && isValid(dateClone) ? `Week of ${format(startOfWeek(dateClone), 'MMM do')}` : invalidLabel;
  };

  renderWrappedWeekDay = (date: any, selectedDate: any, dayInCurrentMonth: any) => {
    const { classes } = this.props;
    const dateClone = Date.parse(date);
    const selectedDateClone = Date.parse(selectedDate);

    const start = startOfWeek(selectedDateClone);
    const end = endOfWeek(selectedDateClone);
    this.sDate = start;
    this.eDate = end;
    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };
  handleYearChange = (year: any | null) => {
    this.setState({
      year: year,
      selectedDate: year,
    });
  };
  onApply = () => {
    const startDate = set(this.sDate, { hours: 0, minutes: 0, seconds: 0 });
    const endDate = set(this.eDate, { hours: 23, minutes: 59, seconds: 59 });
    this.props.onApply(
      mapStringsToTimeRange(
        formatISO(startDate),
        formatISO(endDate),
        this.props.roundup,
        this.props.timeZone,
        this.props.value.range_type
      )
    );
  };
  render() {
    const { selectedDate, year } = this.state;

    return (
      <div className="recent-timpicker-container">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="yyyy"
              openTo="year"
              margin="normal"
              views={['year']}
              id="year-picker-inline"
              label="Year"
              value={year}
              onChange={this.handleYearChange}
              KeyboardButtonProps={{
                'aria-label': 'change year',
              }}
            />
            <KeyboardDatePicker
              autoOk
              disableToolbar
              label="Select Week"
              size="small"
              className="max-width"
              value={selectedDate}
              onChange={(date: any) => this.handleWeekChange(date)}
              renderDay={this.renderWrappedWeekDay}
              labelFunc={this.formatWeekSelectLabel}
              inputVariant="outlined"
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid>
          <button className="timepicker--button-apply" onClick={this.onApply}>
            Apply
          </button>
        </Grid>
      </div>
    );
  }
}

const styles = createStyles((theme: any) => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}));

export default withStyles(styles)(WeekRangeComponent);
