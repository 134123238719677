import { VizOrientation, ReducerID, FieldDisplayOptions, SelectableValue, ThresholdsMode } from '@grafana/data';
import { SingleStatBaseOptions } from '@grafana/ui/src/components/SingleStatShared/SingleStatBaseOptions';

export interface GaugeOptions extends SingleStatBaseOptions {
  showThresholdLabels: boolean;
  showThresholdMarkers: boolean;
  backgroundColor?: string;
  gaugeWidth?: number;
}

export const standardGaugeFieldOptions: FieldDisplayOptions = {
  values: false,
  calcs: [ReducerID.mean],
  defaults: {
    thresholds: {
      mode: ThresholdsMode.Absolute,
      steps: [{ value: -Infinity, color: '#56D8B0' }],
    },
    mappings: [],
  },
  overrides: [],
};

export const orientationOptions: Array<SelectableValue<VizOrientation>> = [
  { value: VizOrientation.Auto, label: 'Auto' },
  { value: VizOrientation.Horizontal, label: 'Horizontal' },
  { value: VizOrientation.Vertical, label: 'Vertical' },
];

export const defaults: GaugeOptions = {
  showThresholdMarkers: false,
  showThresholdLabels: false,
  fieldOptions: standardGaugeFieldOptions,
  orientation: VizOrientation.Auto,
  backgroundColor: '#FB5D9C',
  gaugeWidth: 0.5,
};
