import { SingleStatBaseOptions } from '@grafana/ui';
import { VizOrientation, ReducerID, FieldDisplayOptions } from '@grafana/data';

// Structure copied from angular
export interface DiffPanelOptions extends SingleStatBaseOptions {
  title: string;
}

export const standardFieldDisplayOptions: FieldDisplayOptions = {
  values: false,
  calcs: [ReducerID.mean],
  defaults: {
    mappings: [],
  },
  overrides: [],
};

export const defaults: DiffPanelOptions = {
  fieldOptions: standardFieldDisplayOptions,
  orientation: VizOrientation.Auto,
  title: 'Difference',
};
