import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import formatISO from 'date-fns/formatISO';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { TimeRange, TimeZone } from '@grafana/data';
import { mapStringsToTimeRange } from '../TimePickerContent/mapper';
import lastDayOfYear from 'date-fns/lastDayOfYear';
import set from 'date-fns/set';

interface Props {
  value: TimeRange;
  onApply: (range: TimeRange) => void;
  timeZone?: TimeZone;
  roundup?: boolean;
}
interface State {
  year: any;
}
export default class YearRangeComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      year: props.value.from.format(),
    };
    this.onApply = this.onApply.bind(this);
  }

  handleDateChange = (date: Date | null) => {
    this.setState({ year: date });
  };

  onApply = () => {
    const { year } = this.state;
    const selectedyear = new Date(year).getFullYear();
    let startDay = set(new Date('2020-01-01'), { year: selectedyear, hours: 0, minutes: 0, seconds: 0 });
    const lastDay = set(lastDayOfYear(startDay), { hours: 23, minutes: 59, seconds: 59 });
    this.props.onApply(
      mapStringsToTimeRange(
        formatISO(startDay),
        formatISO(lastDay),
        this.props.roundup,
        this.props.timeZone,
        this.props.value.range_type
      )
    );
  };

  render() {
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              autoOk
              disableToolbar
              size="small"
              variant="inline"
              views={['year']}
              format="yyyy"
              margin="normal"
              inputVariant="outlined"
              id="date-picker-inline"
              label="Date From"
              value={this.state.year}
              onChange={date => this.handleDateChange(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid>
          <button className="timepicker--button-apply" onClick={this.onApply}>
            Apply
          </button>
        </Grid>
      </>
    );
  }
}
