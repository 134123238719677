import $ from 'jquery';
import React, { FunctionComponent } from 'react';
import { appEvents } from 'app/core/core';
import { AppEvents } from '@grafana/data';
import { VariableModel } from 'app/features/templating/variable';
import { variableAdapters } from 'app/features/templating/adapters';
import { VariableListOptions, ElementModel, ElementMap } from './types';

interface Props {
  panel: number;
  element: ElementModel;
  options: VariableListOptions;
  variables: VariableModel[];
}
export const ElementRenderer: FunctionComponent<Props> = props => {
  if (!props.element || !props.element.type || !ElementMap[props.element.type]) {
    return <div>Couldn't load variable</div>;
  }

  const _component = ElementMap[props.element.type];
  // eslint-disable-next-line no-eval
  const _props = eval('(' + (props.element.props || '{}') + ')');

  _props.variant = _props.variant || 'contained';
  _props.color = _props.color || 'secondary';
  _props.className = (_props.className || '') + ' max-width';
  _props.style = _props.style || {};
  _props.style.marginTop = _props.style.marginTop || '2.2em';

  const _onclick = _props.onClick;
  _props.onClick = () => {
    if (props.element.name === props.options.submit && props.options.url) {
      const data: any = {};
      for (const v of props.variables) {
        data[v.name] = (v as any).current.value;
      }
      $('.variable-form-input').each((_, v) => {
        const name = $(v).attr('name');
        data[name] = $(v).val() ? $(v).val() : $('.cards-panel-item__value.' + name + ' span').html();
      });
      $.ajax({
        url: props.options.url + '?v=' + btoa(JSON.stringify(data)),
        type: 'get',
        dataType: 'jsonp',
        success: async data => {
          if (data.error) {
            appEvents.emit(AppEvents.alertError, [data.error]);
          }

          for (const v of props.variables) {
            if (data[v.name]) {
              variableAdapters
                .get(v.type)
                .setValue(v, { selected: true, text: data[v.name].toString(), value: data[v.name].toString() }, true);
            }
          }

          if (data.message) {
            appEvents.emit(AppEvents.alertSuccess, [data.message]);
          }
        },
        error: () => {
          appEvents.emit(AppEvents.alertError, ['Job Failed']);
          // TODO: notify
        },
      });
    }
    if (_onclick) {
      _onclick();
    }
  };

  return (
    <div className="max-width">
      <_component {..._props}>{props.element.html}</_component>
    </div>
  );
};
