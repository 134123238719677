import React, { PureComponent } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { PanelProps } from '@grafana/data';
import { StoreState } from 'app/types';
import { getVariableClones } from 'app/features/templating/state/selectors';
import { VariableModel } from 'app/features/templating/variable';
import { PickerRenderer } from 'app/features/templating/pickers/PickerRenderer';
import { ElementRenderer } from './ElementRenderer';
import { VariableListOptions, ElementModel } from './types';

interface OwnProps extends PanelProps<VariableListOptions> {}
interface ConnectedProps {
  variables: VariableModel[];
}
type Props = OwnProps & ConnectedProps;
export class VariableListPanelUnConnected extends PureComponent<Props> {
  render() {
    const { variables, options, id } = this.props;

    return (
      <div className="submenu-controls">
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        {options.elements.map((element: ElementModel, index: number) => {
          if (element.type === 'Variable') {
            const variable = variables.filter(v => v.name === element.name).pop();
            if (!element.label && variable.hide === 0) {
              variable.hide = 1;
            }
            return (
              variable && (
                <div
                  key={variable.name}
                  className="submenu-item gf-form-inline min-width-12 max-width-45"
                  aria-label="Variable list panel form item"
                >
                  <PickerRenderer variable={variable} />
                </div>
              )
            );
          } else {
            return (
              <div
                key={element.name}
                className="submenu-item gf-form-inline min-width-7 max-width-45"
                aria-label="Variable list panel form item"
              >
                <ElementRenderer element={element} options={options} variables={variables} panel={id} />
              </div>
            );
          }
        })}
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<ConnectedProps, OwnProps, StoreState> = state => ({
  variables: getVariableClones(state, false),
});

export const VariableListPanel = connect(mapStateToProps)(VariableListPanelUnConnected);
VariableListPanel.displayName = 'VariableListPanel';
