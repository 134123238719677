import { Button } from '@material-ui/core';

export const ElementMap: Record<string, any> = {
  Variable: undefined,
  Button: Button,
};

export interface ElementModel {
  name: string;
  label: boolean;
  type: string;
  props?: string;
  html?: string;
}

export const DefaultElement: ElementModel = {
  name: 'button',
  type: 'Button',
  label: true,
};

export interface VariableListOptions {
  elements: ElementModel[];
  submit: string;
  url: string;
}

export const defaults: VariableListOptions = {
  elements: [],
  submit: '',
  url: '',
};
