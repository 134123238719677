import _ from 'lodash';
import { getBackendSrv } from '@grafana/runtime';
import { dateMath, dateTime, PanelEvents } from '@grafana/data';
import { auto, IScope } from 'angular';

import alertDef from '../../../features/alerting/state/alertDef';
import { PanelCtrl } from 'app/plugins/sdk';
import { promiseToDigest } from 'app/core/utils/promiseToDigest';

class AlertListPanel extends PanelCtrl {
  static templateUrl = 'module.html';
  static scrollable = true;

  showOptions = [
    { text: 'Current state', value: 'current' },
    { text: 'Recent state changes', value: 'changes' },
  ];

  sortOrderOptions = [
    { text: 'Alphabetical (asc)', value: 1 },
    { text: 'Alphabetical (desc)', value: 2 },
    { text: 'Importance', value: 3 },
  ];

  priorityMap: any = {
    1: 'High',
    2: 'Medium',
    3: 'Low',
  };

  channels: any = {};
  stateFilter: any = {};
  currentAlerts: any = [];
  alertHistory: any = [];
  noAlertsMessage: string;
  templateSrv: string;

  // Set and populate defaults
  panelDefaults: any = {
    show: 'current',
    limit: 10,
    stateFilter: [],
    onlyAlertsOnDashboard: false,
    sortOrder: 1,
    dashboardFilter: '',
    nameFilter: '',
    folderId: null,
  };

  /** @ngInject */
  constructor($scope: IScope, $injector: auto.IInjectorService) {
    super($scope, $injector);
    _.defaults(this.panel, this.panelDefaults);

    this.events.on(PanelEvents.editModeInitialized, this.onInitEditMode.bind(this));
    this.events.on(PanelEvents.refresh, this.onRefresh.bind(this));
    this.templateSrv = this.$injector.get('templateSrv');

    for (const key in this.panel.stateFilter) {
      this.stateFilter[this.panel.stateFilter[key]] = true;
    }
  }

  sortResult(alerts: any[]) {
    if (this.panel.sortOrder === 3) {
      return _.sortBy(alerts, a => {
        // @ts-ignore
        return alertDef.alertStateSortScore[a.state];
      });
    }

    const result = _.sortBy(alerts, a => {
      return a.name.toLowerCase();
    });
    if (this.panel.sortOrder === 2) {
      result.reverse();
    }

    return result;
  }

  updateStateFilter() {
    const result = [];

    for (const key in this.stateFilter) {
      if (this.stateFilter[key]) {
        result.push(key);
      }
    }

    this.panel.stateFilter = result;
    this.onRefresh();
  }

  onRefresh() {
    let getAlertsPromise;

    if (this.panel.show === 'current') {
      getAlertsPromise = this.getCurrentAlertState();
    }

    if (this.panel.show === 'changes') {
      getAlertsPromise = this.getCurrentAlertState()
        .then(() => this.getNotificationChannels())
        .then(() => this.getStateChanges());
    }

    getAlertsPromise.then(() => {
      this.renderingCompleted();
    });
  }

  onFolderChange = (folder: any) => {
    this.panel.folderId = folder.id;
    this.refresh();
  };

  getNotificationChannels() {
    return promiseToDigest(this.$scope)(
      getBackendSrv()
        .get('/api/alert-notifications', {}, `alert-list-get-channels-${this.panel.id}`)
        .then(data => {
          this.channels = data;
          return this.channels;
        })
    );
  }

  getStateChanges() {
    const params: any = {
      type: 'alert',
    };

    if (this.panel.onlyAlertsOnDashboard) {
      params.dashboardId = this.dashboard.id;
    }

    params.from = dateMath.parse(this.dashboard.time.from).unix() * 1000;
    params.to = dateMath.parse('now').unix() * 1000;

    for (const al of this.currentAlerts) {
      if (al.state !== 'OK') {
        const from = dateTime(al.newStateDate).unix() * 1000;
        if (from < params.from) {
          params.from = from;
        }
      }
    }

    return promiseToDigest(this.$scope)(
      getBackendSrv()
        .get('/api/annotations', params, `alert-list-get-state-changes-${this.panel.id}`)
        .then(data => {
          const states: any = {};
          this.alertHistory = _.orderBy(data, ['time'], ['asc']);
          this.alertHistory = _.map(this.alertHistory, al => {
            const alr = this.currentAlerts.filter((a: any) => a.id === al.alertId)[0];
            al.priority = alr.settings.alertRuleTags.__priority__;
            if (alr.settings.notifications.length > 0) {
              al.assignee = this.channels.filter((a: any) => a.uid === alr.settings.notifications[0].uid).pop()?.name;
            }
            al.timeEnd = this.dashboard.formatDate(al.time, 'MMM D, YYYY HH:mm:ss');
            al.time = states[al.alertId] ? states[al.alertId].time : '';
            states[al.alertId] = {
              state: al.newState,
              time: al.timeEnd,
            };
            // TODO: FIXME
            al.stateModel = alertDef.getStateDisplayModel(alertDef.stateDef, al.newState);
            al.info = alertDef.getAlertAnnotationInfo(al);
            al = al && al.newState === 'OK' ? undefined : al; // filter alerts that are ok
            return al;
          });
          this.alertHistory = _.compact(this.alertHistory);
          this.alertHistory = _.map(this.alertHistory, al => {
            al.timeEnd = states[al.alertId].time === al.timeEnd ? '' : al.timeEnd;
            if (!al.time && !al.timeEnd) {
              al.time = this.currentAlerts.filter((v: any) => v.id === al.alertId).pop()?.newStateDate;
            }
            al = al && !al.time ? undefined : al; // filter the alerts for which start time is out of range
            return al;
          });
          this.alertHistory = _.compact(this.alertHistory);
          this.alertHistory = _.concat(
            this.alertHistory.filter((v: any) => !v.timeEnd),
            this.alertHistory.filter((v: any) => v.timeEnd)
          );
          this.noAlertsMessage = this.alertHistory.length === 0 ? 'No alerts in current time range' : '';

          return this.alertHistory;
        })
    );
  }

  getCurrentAlertState() {
    const params: any = {
      state: this.panel.stateFilter,
    };

    if (this.panel.nameFilter) {
      params.query = this.templateSrv.replace(this.panel.nameFilter, this.panel.scopedVars);
    }

    if (this.panel.folderId >= 0) {
      params.folderId = this.panel.folderId;
    }

    if (this.panel.dashboardFilter) {
      params.dashboardQuery = this.panel.dashboardFilter;
    }

    if (this.panel.onlyAlertsOnDashboard) {
      params.dashboardId = this.dashboard.id;
    }

    if (this.panel.dashboardTags) {
      params.dashboardTag = this.panel.dashboardTags;
    }

    return promiseToDigest(this.$scope)(
      getBackendSrv()
        .get('/api/alerts', params, `alert-list-get-current-alert-state-${this.panel.id}`)
        .then(data => {
          this.currentAlerts = this.sortResult(
            _.map(data, al => {
              al.value = Math.round((al.evalData?.evalMatches || [{}])[0].value * 100) / 100;
              // TODO: FIXME
              al.stateModel = alertDef.getStateDisplayModel(alertDef.stateDef, al.state);
              al.unit = al.name.substr(
                al.name.lastIndexOf('(') + 1,
                al.name.lastIndexOf(')') - al.name.lastIndexOf('(') - 1
              );
              al.name = al.name.substr(0, al.name.lastIndexOf('(') - 1);
              al.newStateDateAgo = dateTime(al.newStateDate)
                .locale('en')
                .fromNow(true);
              al.newStateDate = this.dashboard.formatDate(al.newStateDate, 'MMM D, YYYY HH:mm:ss');
              return al;
            })
          );
          if (this.currentAlerts.length > this.panel.limit) {
            this.currentAlerts = this.currentAlerts.slice(0, this.panel.limit);
          }
          this.noAlertsMessage = this.currentAlerts.length === 0 ? 'No alerts' : '';

          return this.currentAlerts;
        })
    );
  }

  onInitEditMode() {
    this.addEditorTab('Options', 'public/app/plugins/panel/alertlist/editor.html');
  }
}

export { AlertListPanel, AlertListPanel as PanelCtrl };
