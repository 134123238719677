import { SingleStatBaseOptions } from '@grafana/ui';
import { ReducerID, FieldDisplayOptions } from '@grafana/data';

// Structure copied from angular
export interface CardsPanelOptions extends Omit<SingleStatBaseOptions, 'orientation'> {
  showChange?: boolean;
  showChangeFilter?: string;
  disableSubmit?: boolean;
  color?: string;
}

export const standardFieldDisplayOptions: FieldDisplayOptions = {
  values: false,
  calcs: [ReducerID.mean],
  defaults: {
    mappings: [],
  },
  overrides: [],
};

export const defaults: CardsPanelOptions = {
  fieldOptions: standardFieldDisplayOptions,
  color: '#666262',
};
